// Abilities are used to describe:
// - base stats of figures
// - ability card actions (players and monsters)
// In each case, a simple array of Ability objects is used.
//
// Most abilities have 1 or more targets and should be consistently deducable
// from the array.
//
// Multiple abilities might depend on each other (e.g., use the same targets)
// and should be made apparent in the UI. Pseudo abilites are used to either
// draw a line between abilities, or combine abilities into a single line.
// Though, a front end can render them differently.
import type { HashMap } from './utility.js';

export type Action = string[];

export enum AoeHexKind {
  NONE = 0,
  SELF = 1,
  TARGET = 2,
  ALLY = 3,
  ENHANCE = 16,
}

export interface AoeHex {
  q: number;
  r: number;
  kind: AoeHexKind;
}

export interface Ability {
  kind: AbilityKind;
  value?: string | number; // The value of kind, depending on Ability.eval
  value_elite?: string | number;
  factions?: number;
  eval?: number;
  layout?: number;
  conditions?: number[];
  conditions_elite?: number[]; // this should only be set for ATTACK or MOVE
  elements?: string[];
  text?: string; // TODO: combine with target_text. When set always display it.
  aoe_hexes?: AoeHex[];
  actions?: Action[]; // summon: [ info_id, target, 2p, 3p, 4p ]
  // abilities in the effects array never have an effects or nested field.
  effects?: Ability[]; // limited to range, pierce, push, pull, multi
  nested?: Ability[]; // other effects that modify the ability in some way
  delta?: string; // When combining, save the delta for display
  info_id?: number; // info_id of monster being granted actions or targeted
  enhancements?: number[]; // index to AbilityCardInfo.slots
  custom?: number[];
  no_display?: boolean;
}

export interface AbilityCompat extends Ability {
  hexes?: number[];
}

// Remember to edit related code when changing this enum.
// Some of these should appear only as nested abilities.
export enum AbilityKind {
  NONE = 0,
  MOVE = 1,
  SWIM = 2,
  JUMP = 3,
  SWIM_JUMP = 4,
  FLY = 5,
  TELEPORT = 6,
  ATTACK = 7,
  ATTACK_MELEE = 8,
  ATTACK_RANGED = 9,
  CONDITION_EFFECT = 10,
  CONDITION_IMMUNE = 11,
  CONDITION_PERSIST = 12,
  TARGET = 13,
  TARGET_ONE = 14,
  TARGET_MULTI = 15,
  TARGET_ALL_ADJACENT = 16,
  TARGET_ALL_IN_RANGE = 17,
  TARGET_ALL_IN_RANGE_X = 18,
  TARGET_ALL_ON_SAME_TILE = 19,
  TARGET_ALL = 20,
  TARGET_TEXT = 21,
  TARGET_AOE_MELEE = 22,
  TARGET_AOE_RANGE = 23,
  TARGET_ALL_ADJACENT_TO_TARGET = 24,
  TARGET_ONE_ADJACENT = 25,
  TARGET_THE_TARGET = 26,
  TARGET_ON_THE_MOVE = 27,
  TARGET_ALL_WITH_CONDITION = 28,
  RANGE = 29,
  RANGE_MELEE = 30,
  PIERCE = 31,
  PUSH = 32,
  PULL = 33,
  HEAL = 34,
  DAMAGE = 35,
  ATTACK_DAMAGE = 36,
  RETALIATE_DAMAGE = 37,
  LOOT = 38,
  SUMMON = 39,
  SPAWN = 40,
  CHARACTER_SUMMON = 41,
  CREATE_TRAP = 42,
  DISARM_TRAP = 43,
  INFUSE = 44,
  CONSUME = 45,
  SPECIAL_1 = 46,
  SPECIAL_2 = 47,
  PERSISTENT = 48,
  ADVANTAGED_ATTACK = 49,
  ADVANTAGED = 50,
  ADVANTAGED_END_OF_ROUND = 51,
  ATTACKER_DISADVANTAGED = 52,
  ATTACKER_DISADVANTAGED_END_OF_ROUND = 53,
  SHIELD = 54,
  SHIELD_END_OF_ROUND = 55,
  RETALIATE = 56,
  RETALIATE_END_OF_ROUND = 57,
  RETALIATE_RANGE = 58,
  RETALIATE_RANGE_END_OF_ROUND = 59,
  TEXT = 60,
  TEXT_HELP = 61,
  TARGET_ONLY = 62,
  IF = 63,
  IF_CONSUME = 64,
  IF_NORMAL = 65,
  IF_ELITE = 66,
  WHEN_TARGET_ADJACENT_TO_ALLIES = 67,
  ON_DEATH = 68,
  IF_PERFORM = 69,
  IF_STAT = 70,
  IF_ACTIVE = 71,
  IF_USE_SLOT = 72,
  IF_CONSUME_ONE_OF = 73,
  IF_HAVE_CONDITION = 74,
  IF_HAVE_STAT = 75,
  IF_EXPR = 76,
  IF_ON_TILE = 77,
  HATCH = 78,
  SWAP_VARIANTS = 79,
  CARD_IMAGE = 80,
  CYCLE_BEGIN = 81,
  CYCLE_END = 82,
  CYCLE_ADD = 83,
  XP = 84,
  STAT = 85,
  KILL = 86,
  DESTROY = 87,
  TRANSFER = 88,
  CARD_DEFAULT = 89,
  CARD_TOP = 90,
  CARD_BOTTOM = 91,
  CREATE_OVERLAY = 92,
  FOCUS_ON_FARTHEST = 93,
  TARGET_MULTI_ONE_WITH_ALL_ATTACKS = 94,
  TARGET_ADJACENT_EMPTY_HEX = 95,
  TARGET_EMPTY_HEX_IN_RANGE = 96,
  AFFECT_ALL_ADJACENT = 97,
  AFFECT_ALL_IN_RANGE_X = 98,
  AFFECT_ALL = 99,
}

export enum AbilityEval {
  DEFAULT = 0, // On an ability card, add the base. On a stat card it is the base.
  EXPR = 1,
  AS_IS = 2,
  // Display as a delta. TODO: Which ability does it apply to for the sake of
  // auto? What if there is more than one matching? I'll assume it is always
  // obvious for now. i.e., the previous ability?
  DELTA = 3,
  TEXT = 4, // Don't know the value, just display the text
  TARGET = 5, // performed as the target
}

export enum Faction {
  // When targeting a combination of factions. Otherwise, default is enemies for a
  // negative ability and allies for a positive ability.
  SELF = 0x1,
  ALLIES = 0x2,
  ENEMIES = 0x4,
  FIGURES = 0x7,
  OBSTACLES = 0x8,
  OBJECTIVES = 0x10,
  BOSSES = 0x20,
  NORMAL = 0x40,
  ELITE = 0x80,
  SUMMONS = 0x100,
}

export enum AbilityLayout {
  COLUMN2 = 1,
}

export const move_kinds = [
  AbilityKind.MOVE,
  AbilityKind.SWIM,
  AbilityKind.JUMP,
  AbilityKind.SWIM_JUMP,
  AbilityKind.FLY,
  AbilityKind.TELEPORT,
];

type AbilityKindInfo = {
  kind: AbilityKind;
  parent?: AbilityKind;
  name: string;
  short_name?: string;
  icon?: string;
  text?: string;
  has_value?: boolean;
  layout?: string;
  default_target?: Faction;
};

export const _ability_kind_info: AbilityKindInfo[] = [
  { kind: AbilityKind.NONE, name: 'none', layout: 'stat' },
  { kind: AbilityKind.MOVE, parent: AbilityKind.MOVE, name: 'move', layout: 'stat' },
  {
    kind: AbilityKind.SWIM,
    parent: AbilityKind.MOVE,
    name: 'swim',
    icon: 'move',
    layout: 'stat',
  },
  { kind: AbilityKind.JUMP, parent: AbilityKind.MOVE, name: 'jump', layout: 'stat' },
  {
    kind: AbilityKind.SWIM_JUMP,
    parent: AbilityKind.MOVE,
    name: 'swim_jump',
    icon: 'jump',
    layout: 'stat',
  },
  { kind: AbilityKind.FLY, parent: AbilityKind.MOVE, name: 'fly', layout: 'stat' },
  {
    kind: AbilityKind.TELEPORT,
    parent: AbilityKind.MOVE,
    name: 'teleport',
    layout: 'stat',
  },
  {
    kind: AbilityKind.ATTACK,
    parent: AbilityKind.ATTACK,
    name: 'attack',
    default_target: Faction.ENEMIES,
    layout: 'stat',
  },
  {
    kind: AbilityKind.ATTACK_MELEE,
    parent: AbilityKind.ATTACK,
    name: 'attack_melee',
    icon: 'attack',
    default_target: Faction.ENEMIES,
    layout: 'stat',
  },
  {
    kind: AbilityKind.ATTACK_RANGED,
    parent: AbilityKind.ATTACK,
    name: 'attack_ranged',
    icon: 'attack',
    default_target: Faction.ENEMIES,
    layout: 'stat',
  },
  { kind: AbilityKind.CONDITION_EFFECT, name: 'condition_effect', layout: 'condition' },
  { kind: AbilityKind.CONDITION_IMMUNE, name: 'condition_immune', layout: 'condition' },
  { kind: AbilityKind.CONDITION_PERSIST, name: 'condition_persist', layout: 'condition' },
  {
    kind: AbilityKind.TARGET,
    parent: AbilityKind.TARGET,
    name: 'target',
    layout: 'stat',
  },
  {
    kind: AbilityKind.TARGET_ONE,
    parent: AbilityKind.TARGET,
    name: 'target_one',
    icon: 'target',
    layout: 'stat',
  },
  {
    kind: AbilityKind.TARGET_MULTI,
    parent: AbilityKind.TARGET,
    name: 'target_multi',
    icon: 'target',
    layout: 'stat',
  },
  {
    kind: AbilityKind.TARGET_ALL_ADJACENT,
    parent: AbilityKind.TARGET,
    name: 'target_all_adjacent',
    icon: 'target',
    layout: 'stat',
  },
  {
    kind: AbilityKind.TARGET_ALL_IN_RANGE,
    parent: AbilityKind.TARGET,
    name: 'target_all_in_range',
    icon: 'target',
    layout: 'stat',
  },
  {
    kind: AbilityKind.TARGET_ALL_IN_RANGE_X,
    parent: AbilityKind.TARGET,
    name: 'target_all_in_range_x',
    icon: 'target',
    layout: 'stat',
  },
  {
    kind: AbilityKind.TARGET_ALL_ON_SAME_TILE,
    parent: AbilityKind.TARGET,
    name: 'target_all_on_same_tile',
    icon: 'target',
    layout: 'stat',
  },
  {
    kind: AbilityKind.TARGET_ALL,
    parent: AbilityKind.TARGET,
    name: 'target_all',
    icon: 'target',
    layout: 'stat',
  },
  {
    kind: AbilityKind.TARGET_TEXT,
    parent: AbilityKind.TARGET,
    name: 'target_text',
    icon: 'target',
    layout: 'stat',
  },
  {
    kind: AbilityKind.TARGET_AOE_MELEE,
    parent: AbilityKind.TARGET,
    name: 'target_aoe_melee',
    icon: 'target',
    layout: 'stat',
  },
  {
    kind: AbilityKind.TARGET_AOE_RANGE,
    parent: AbilityKind.TARGET,
    name: 'target_aoe_range',
    icon: 'target',
    layout: 'stat',
  },
  {
    kind: AbilityKind.TARGET_ALL_ADJACENT_TO_TARGET,
    parent: AbilityKind.TARGET,
    name: 'target_all_adjacent_to_target',
    icon: 'target',
    layout: 'stat',
  },
  {
    kind: AbilityKind.TARGET_ONE_ADJACENT,
    parent: AbilityKind.TARGET,
    name: 'target_one_adjacent',
    icon: 'target',
    layout: 'stat',
  },
  {
    kind: AbilityKind.TARGET_THE_TARGET,
    parent: AbilityKind.TARGET,
    name: 'target_the_target',
    icon: 'target',
    layout: 'stat',
  },
  {
    kind: AbilityKind.TARGET_ON_THE_MOVE,
    parent: AbilityKind.TARGET,
    name: 'target_on_the_move',
    icon: 'target',
    layout: 'stat',
  },
  {
    kind: AbilityKind.TARGET_ALL_WITH_CONDITION,
    parent: AbilityKind.TARGET,
    name: 'target_all_with_condition',
    icon: 'target',
    layout: 'stat',
  },
  { kind: AbilityKind.RANGE, name: 'range', layout: 'stat' },
  { kind: AbilityKind.RANGE_MELEE, name: 'range_melee', icon: 'range', layout: 'stat' },
  { kind: AbilityKind.PIERCE, name: 'pierce', layout: 'stat' },
  { kind: AbilityKind.PUSH, name: 'push', default_target: Faction.ENEMIES },
  { kind: AbilityKind.PULL, name: 'pull', default_target: Faction.ENEMIES },
  {
    kind: AbilityKind.HEAL,
    name: 'heal',
    default_target: Faction.SELF | Faction.ALLIES,
    layout: 'stat',
  },
  { kind: AbilityKind.DAMAGE, name: 'damage', default_target: Faction.ENEMIES },
  {
    kind: AbilityKind.ATTACK_DAMAGE,
    parent: AbilityKind.DAMAGE,
    name: 'attack_damage',
    icon: 'damage',
    layout: 'stat',
  },
  {
    kind: AbilityKind.RETALIATE_DAMAGE,
    parent: AbilityKind.DAMAGE,
    name: 'retaliate_damage',
    icon: 'damage',
    layout: 'stat',
  },
  { kind: AbilityKind.LOOT, name: 'loot', default_target: Faction.SELF },
  {
    kind: AbilityKind.SUMMON,
    parent: AbilityKind.SUMMON,
    name: 'summon',
    layout: 'text',
  },
  { kind: AbilityKind.SPAWN, parent: AbilityKind.SUMMON, name: 'spawn', layout: 'text' },
  {
    kind: AbilityKind.CHARACTER_SUMMON,
    parent: AbilityKind.SUMMON,
    name: 'character_summon',
    layout: 'text',
  },
  { kind: AbilityKind.CREATE_TRAP, name: 'create_trap', layout: 'text' },
  { kind: AbilityKind.DISARM_TRAP, name: 'disarm_trap', layout: 'text' },
  { kind: AbilityKind.INFUSE, name: 'infuse', layout: 'element' },
  { kind: AbilityKind.CONSUME, name: 'consume', layout: 'element' },
  { kind: AbilityKind.SPECIAL_1, name: 'special_1', layout: 'text' },
  { kind: AbilityKind.SPECIAL_2, name: 'special_2', layout: 'text' },
  { kind: AbilityKind.PERSISTENT, name: 'persistent_none', layout: 'stat' },
  {
    kind: AbilityKind.ADVANTAGED_ATTACK,
    parent: AbilityKind.PERSISTENT,
    name: 'advantaged_attack',
    text: 'Advantage',
    default_target: Faction.SELF,
    layout: 'stat',
  },
  {
    kind: AbilityKind.ADVANTAGED,
    parent: AbilityKind.PERSISTENT,
    name: 'advantaged',
    text: 'Advantage',
    default_target: Faction.SELF,
    layout: 'stat',
  },
  {
    kind: AbilityKind.ADVANTAGED_END_OF_ROUND,
    parent: AbilityKind.PERSISTENT,
    name: 'advantaged_end_of_round',
    text: 'Advantage',
    default_target: Faction.SELF,
    layout: 'stat',
  },
  {
    kind: AbilityKind.ATTACKER_DISADVANTAGED,
    parent: AbilityKind.PERSISTENT,
    name: 'attacker_disadvantaged',
    text: 'Attackers gain Disdvantage',
    default_target: Faction.SELF,
    layout: 'stat',
  },
  {
    kind: AbilityKind.ATTACKER_DISADVANTAGED_END_OF_ROUND,
    parent: AbilityKind.PERSISTENT,
    name: 'attacker_disadvantaged_end_of_round',
    text: 'Attackers gain Disdvantage',
    default_target: Faction.SELF,
    layout: 'stat',
  },
  {
    kind: AbilityKind.SHIELD,
    parent: AbilityKind.PERSISTENT,
    name: 'shield',
    default_target: Faction.SELF,
    layout: 'stat',
  },
  {
    kind: AbilityKind.SHIELD_END_OF_ROUND,
    parent: AbilityKind.PERSISTENT,
    name: 'shield_end_of_round',
    short_name: 'shield',
    default_target: Faction.SELF,
    layout: 'stat',
  },
  {
    kind: AbilityKind.RETALIATE,
    parent: AbilityKind.PERSISTENT,
    name: 'retaliate',
    default_target: Faction.SELF,
    layout: 'stat',
  },
  {
    kind: AbilityKind.RETALIATE_END_OF_ROUND,
    parent: AbilityKind.PERSISTENT,
    name: 'retaliate_end_of_round',
    short_name: 'retaliate',
    default_target: Faction.SELF,
    layout: 'stat',
  },
  {
    kind: AbilityKind.RETALIATE_RANGE,
    parent: AbilityKind.PERSISTENT,
    name: 'retaliate_range',
    icon: 'range',
    default_target: Faction.SELF,
    layout: 'stat',
  },
  {
    kind: AbilityKind.RETALIATE_RANGE_END_OF_ROUND,
    parent: AbilityKind.PERSISTENT,
    name: 'retaliate_range_end_of_round',
    icon: 'range',
    default_target: Faction.SELF,
    layout: 'stat',
  },
  { kind: AbilityKind.TEXT, parent: AbilityKind.TEXT, name: 'text', layout: 'text' },
  {
    kind: AbilityKind.TEXT_HELP,
    parent: AbilityKind.TEXT,
    name: 'help',
  },
  { kind: AbilityKind.TARGET_ONLY, name: 'target_only' },
  { kind: AbilityKind.IF, parent: AbilityKind.IF, name: 'if' },
  {
    kind: AbilityKind.IF_CONSUME,
    parent: AbilityKind.IF,
    name: 'if consume',
  },
  {
    kind: AbilityKind.IF_NORMAL,
    parent: AbilityKind.IF,
    name: 'if vnormal',
  },
  {
    kind: AbilityKind.IF_ELITE,
    parent: AbilityKind.IF,
    name: 'if velite',
  },
  {
    kind: AbilityKind.WHEN_TARGET_ADJACENT_TO_ALLIES,
    parent: AbilityKind.IF,
    name: 'if target_adjacent_to_allies',
  },
  {
    kind: AbilityKind.ON_DEATH,
    parent: AbilityKind.IF,
    name: 'if on_death',
  },
  {
    kind: AbilityKind.IF_PERFORM,
    parent: AbilityKind.IF,
    name: 'if perform',
  },
  { kind: AbilityKind.IF_STAT, parent: AbilityKind.IF, name: 'if stat' },
  {
    kind: AbilityKind.IF_ACTIVE,
    parent: AbilityKind.IF,
    name: 'if active',
  },
  {
    kind: AbilityKind.IF_USE_SLOT,
    parent: AbilityKind.IF,
    name: 'if use_slot',
  },
  {
    kind: AbilityKind.IF_CONSUME_ONE_OF,
    parent: AbilityKind.IF,
    name: 'if consume one_of',
  },
  {
    kind: AbilityKind.IF_HAVE_CONDITION,
    parent: AbilityKind.IF,
    name: 'if have_condition',
  },
  {
    kind: AbilityKind.IF_HAVE_STAT,
    parent: AbilityKind.IF,
    name: 'if have_stat',
  },
  { kind: AbilityKind.IF_EXPR, parent: AbilityKind.IF, name: 'if_expr', layout: 'if' },
  {
    kind: AbilityKind.IF_ON_TILE,
    parent: AbilityKind.IF,
    name: 'if on_tile',
  },
  { kind: AbilityKind.HATCH, name: 'hatch', layout: 'text' },
  { kind: AbilityKind.SWAP_VARIANTS, name: 'swap_variants', layout: 'text' },
  { kind: AbilityKind.CARD_IMAGE, name: 'card_image', layout: 'card' },
  { kind: AbilityKind.CYCLE_BEGIN, name: 'cycle_begin', layout: 'cycle' },
  { kind: AbilityKind.CYCLE_END, name: 'cycle_end', layout: 'cycle' },
  { kind: AbilityKind.CYCLE_ADD, name: 'cycle_add', layout: 'cycle' },
  { kind: AbilityKind.XP, name: 'xp', default_target: Faction.SELF },
  { kind: AbilityKind.STAT, name: 'stat', default_target: Faction.SELF },
  {
    kind: AbilityKind.KILL,
    name: 'kill',
    icon: 'skill',
    layout: 'text',
    default_target: Faction.ENEMIES,
  },
  { kind: AbilityKind.DESTROY, name: 'destroy', icon: 'skill', layout: 'text' },
  { kind: AbilityKind.TRANSFER, name: 'transfer', default_target: Faction.SUMMONS },
  { kind: AbilityKind.CARD_DEFAULT, name: 'card_default', layout: 'card' },
  {
    kind: AbilityKind.CARD_TOP,
    name: 'card_top',
    layout: 'card',
    default_target: Faction.SELF,
  },
  {
    kind: AbilityKind.CARD_BOTTOM,
    name: 'card_bottom',
    default_target: Faction.SELF,
    layout: 'card',
  },
  { kind: AbilityKind.CREATE_OVERLAY, name: 'create_overlay', layout: 'overlay' },
  {
    kind: AbilityKind.FOCUS_ON_FARTHEST,
    parent: AbilityKind.TEXT,
    name: 'focus_on_farthest',
    layout: 'text',
  },
  {
    kind: AbilityKind.TARGET_MULTI_ONE_WITH_ALL_ATTACKS,
    parent: AbilityKind.TARGET,
    name: 'target_multi_one_with_all_attacks',
    icon: 'target',
    layout: 'stat',
  },
  {
    kind: AbilityKind.TARGET_ADJACENT_EMPTY_HEX,
    parent: AbilityKind.TARGET,
    name: 'target_adjacent_empty_hex',
    icon: 'target',
    layout: 'text',
  },
  {
    kind: AbilityKind.TARGET_EMPTY_HEX_IN_RANGE,
    parent: AbilityKind.TARGET,
    name: 'target_empty_hex_in_range',
    icon: 'target',
    layout: 'text',
  },
  {
    kind: AbilityKind.AFFECT_ALL_ADJACENT,
    parent: AbilityKind.TARGET,
    name: 'affect_all_adjacent',
    icon: 'target',
    layout: 'stat',
  },
  {
    kind: AbilityKind.AFFECT_ALL_IN_RANGE_X,
    parent: AbilityKind.TARGET,
    name: 'affect_all_in_range_x',
    icon: 'target',
    layout: 'stat',
  },
  {
    kind: AbilityKind.AFFECT_ALL,
    parent: AbilityKind.TARGET,
    name: 'affect_all',
    icon: 'target',
    layout: 'stat',
  },
];

_ability_kind_info.forEach((info, i) => {
  if (info.kind !== i) console.log('Error', info);
});

interface HasAbilityKind {
  kind: AbilityKind;
}

export function ability_icon(ability: Ability): string {
  if (!ability || !_ability_kind_info[ability.kind]) return null;
  const info = _ability_kind_info[ability.kind];
  return info.icon || info.name;
}

export function ability_full_name(ability: Ability): string {
  return ability && _ability_kind_info[ability.kind]
    ? _ability_kind_info[ability.kind].name
    : '?no ability?';
}

export function ability_name(ability: Ability): string {
  if (!ability) return '?no ability?';
  const info = _ability_kind_info[ability.kind];
  if (!info) return '?no info?';
  return info.short_name ?? info.icon ?? info.name;
}

export function ability_delta(ability: Ability): string {
  return ability.delta;
}

export function ability_layout(ability: Ability): string {
  if (!ability) return 'text';
  const info = _ability_kind_info[ability.kind];
  return info?.layout ?? info?.name ?? 'text';
}

export function ability_column(ability: Ability): number {
  return ability.layout === AbilityLayout.COLUMN2 ? 2 : 1;
}

export function faction_names(factions: Faction, join: string, names: string[]) {
  // skip 'self'
  const result: string[] = [];
  for (let f = 1; f < names.length; f++) {
    if (factions & (1 << f)) result.push(names[f]);
  }
  if (result.length === 0) return 'none'; // shouldn't happen
  if (result.length === 1) return result[0];
  if (result.length === 2) return result[0] + join + result[1];
  return result.slice(0, -1).join(', ') + ',' + join + result[result.length - 1];
}

export function target_description(
  target: Ability,
  no_prefix?: boolean,
  no_within?: boolean
): string {
  if (!target) return '';
  const tgt_kind = target.kind;
  if (tgt_kind === AbilityKind.TARGET_THE_TARGET) return 'The target';
  if (tgt_kind === AbilityKind.TARGET_TEXT) {
    if (no_prefix && target.text?.startsWith('Target ')) return target.text.slice(7);
    return target.text ?? '';
  }
  const tgt_factions = target.factions;
  if (tgt_factions === Faction.SELF) return 'self';
  const is_plural = is_target_plural(target);
  const is_figures = tgt_factions === Faction.FIGURES;
  const self =
    is_figures || (tgt_factions & Faction.SELF) === 0
      ? ''
      : is_plural
      ? 'self and '
      : 'self or ';
  const factions = is_figures
    ? is_plural
      ? 'figures'
      : 'figure'
    : is_plural
    ? faction_names(tgt_factions, 'and', faction_plural)
    : faction_names(tgt_factions, 'or', faction_single);
  let prefix = no_prefix ? '' : 'Target ';
  switch (tgt_kind) {
    case AbilityKind.TARGET_ONE:
      return `${prefix}${self}one ${factions}`;
    case AbilityKind.TARGET_ONE_ADJACENT:
      return `${prefix}${self}one adjacent ${factions}`;
    case AbilityKind.AFFECT_ALL:
      if (!no_prefix) prefix = 'Affect ';
    // fall through
    case AbilityKind.TARGET_ALL:
      return `${prefix}${self}all ${factions}`;
    case AbilityKind.AFFECT_ALL_ADJACENT:
      if (!no_prefix) prefix = 'Affect ';
    // fall through
    case AbilityKind.TARGET_ALL_ADJACENT:
      return `${prefix}${self}all adjacent ${factions}`;
    case AbilityKind.TARGET_ALL_IN_RANGE:
      return `${prefix}${self}all ${factions} within range`;
    case AbilityKind.AFFECT_ALL_IN_RANGE_X:
      if (!no_prefix) prefix = 'Affect ';
    // fall through
    case AbilityKind.TARGET_ALL_IN_RANGE_X:
      const range = (no_within ? '' : 'within ') + 'Range ' + String(target.value);
      return `${prefix}${self}all ${factions} ${range}`;
    case AbilityKind.TARGET_ALL_ON_SAME_TILE:
      return `${prefix}${self}all ${factions} on the same tile`;
    case AbilityKind.TARGET_ALL_ADJACENT_TO_TARGET:
      return `${prefix}${self}all ${factions} adjacent to the target`;
    case AbilityKind.TARGET_ALL_WITH_CONDITION:
      return `${prefix}${self}all ${factions} with ${target.conditions?.[0]}`;
    case AbilityKind.TARGET_MULTI_ONE_WITH_ALL_ATTACKS:
      return `${prefix}one enemy with all attacks`;
    case AbilityKind.TARGET_MULTI:
    case AbilityKind.TARGET_AOE_MELEE:
    case AbilityKind.TARGET_AOE_RANGE:
    case AbilityKind.TARGET_ON_THE_MOVE:
      // These are handled above, causing a compile time error here.
      // case AbilityKind.TARGET_THE_TARGET:
      // case AbilityKind.TARGET_TEXT:
      return '';
  }
  return '';
}

const faction_single: string[] = [
  'self',
  'ally',
  'enemy',
  'obstacle',
  'objective',
  'boss',
  'normal',
  'elite',
  'summon',
];

const faction_plural: string[] = [
  'self',
  'allies',
  'enemies',
  'obstacles',
  'objectives',
  'bosses',
  'normal',
  'elite',
  'summons',
];

const target_text_map: HashMap<string> = {};

export function is_kind(ability: Ability, ...kinds: AbilityKind[]): boolean {
  return ability && kinds.includes(ability.kind);
}

export function is_target_plural(target: Ability): boolean {
  const kind = target ? target.kind : AbilityKind.TARGET_ONE;
  return ![
    AbilityKind.TARGET_ONE,
    AbilityKind.TARGET_ONE_ADJACENT,
    AbilityKind.TARGET_THE_TARGET,
  ].includes(kind);
}

export function is_target_self_only(ability: Ability): boolean {
  return ability.factions === Faction.SELF;
}

export function is_target_self(ability: Ability): boolean {
  return (ability.factions & Faction.SELF) !== 0;
}

export function is_target_allies(ability: Ability): boolean {
  return (ability.factions & Faction.ALLIES) !== 0;
}

export function is_target_enemies(ability: Ability): boolean {
  return (ability.factions & Faction.ENEMIES) !== 0;
}

export function is_target_all_with_cond(ability: Ability): boolean {
  return (
    ability?.kind === AbilityKind.TARGET_ALL_WITH_CONDITION &&
    ability?.conditions?.length === 1
  );
}

export function is_target_multi(ability: Ability): boolean {
  return is_kind(
    ability,
    AbilityKind.TARGET_MULTI,
    AbilityKind.TARGET_MULTI_ONE_WITH_ALL_ATTACKS
  );
}

export function is_target_adjacent(ability: Ability): boolean {
  return (
    ability?.kind === AbilityKind.TARGET_ALL_ADJACENT ||
    ability?.kind === AbilityKind.AFFECT_ALL_ADJACENT ||
    ability?.kind === AbilityKind.TARGET_ONE_ADJACENT
  );
}

// What to display for condensed targets.
export function fh_target_text(ability: Ability): string {
  if (is_target_self_only(ability)) return null;
  return target_description(ability.effects?.find(is_target), true, true);
}

export function target_text(ability: Ability): string {
  if (is_target_self_only(ability)) return ability.text ? '' : 'Self';
  return target_description(ability.effects?.find(is_target));
}

export function use_separator(abilities: Ability[], index: number): boolean {
  if (index <= 0) return false;
  const ability = abilities[index];
  if (ability.kind === AbilityKind.INFUSE || ability.kind === AbilityKind.CONSUME)
    return false;
  if (ability_column(ability) > 1) return false;
  return true;
}

export function is_eval_value(ability: Ability): boolean {
  return !ability.eval;
}

export function is_eval_text(ability: Ability): boolean {
  return ability.eval === AbilityEval.TEXT;
}

export function is_eval_expr(ability: Ability): boolean {
  return ability.eval === AbilityEval.EXPR;
}

export function is_eval_delta(ability: Ability) {
  return ability.eval === AbilityEval.DELTA;
}

export function is_eval_as_is(ability: Ability) {
  return ability.eval === AbilityEval.AS_IS;
}

export function is_eval_target(ability: Ability) {
  return ability.eval === AbilityEval.TARGET;
}

export function is_melee_attack(ability: Ability): boolean {
  return ability && ability.kind === AbilityKind.ATTACK_MELEE;
}

export function is_aoe_melee(ability: Ability): boolean {
  return ability && ability.kind === AbilityKind.TARGET_AOE_MELEE;
}

export function is_aoe(ability: Ability): boolean {
  if (!ability) return false;
  return (
    ability.kind === AbilityKind.TARGET_AOE_MELEE ||
    ability.kind === AbilityKind.TARGET_AOE_RANGE
  );
}

export function is_if(ability: Ability): boolean {
  return ability && _ability_kind_info[ability.kind].parent === AbilityKind.IF;
}

// Returns true when the effect should be displayed as a stat, i.e., with icon
// and variant values. Filters out a TARGET that is not MULTI, which is handled
// elsewhere in that case.
export function is_stat_effect(ability: Ability): boolean {
  if (!ability) return false;
  return !is_target(ability) || is_target_multi(ability);
}

export function is_target(ability: Ability): boolean {
  return ability && _ability_kind_info[ability.kind].parent === AbilityKind.TARGET;
}

export function is_target_text(ability: Ability): boolean {
  // TODO: fixme.
  return is_target(ability) && Boolean(ability.text);
}

export function is_persistent(ability: Ability): boolean {
  return ability && _ability_kind_info[ability.kind].parent === AbilityKind.PERSISTENT;
}

export function is_persistent_stat(ability: Ability): boolean {
  if (!ability) return false;
  return [
    AbilityKind.SHIELD,
    AbilityKind.SHIELD_END_OF_ROUND,
    AbilityKind.RETALIATE,
    AbilityKind.RETALIATE_END_OF_ROUND,
    AbilityKind.RETALIATE_RANGE,
    AbilityKind.RETALIATE_RANGE_END_OF_ROUND,
  ].includes(ability.kind);
}

export function is_persistent_eor(ability: Ability): boolean {
  if (!ability) return false;
  return [
    AbilityKind.SHIELD_END_OF_ROUND,
    AbilityKind.RETALIATE_END_OF_ROUND,
    AbilityKind.RETALIATE_RANGE_END_OF_ROUND,
  ].includes(ability.kind);
}

export function is_retaliate(ability: Ability): boolean {
  return (
    is_persistent(ability) &&
    is_kind(ability, AbilityKind.RETALIATE, AbilityKind.RETALIATE_END_OF_ROUND)
  );
}

export function is_retaliate_range(ability: Ability): boolean {
  return is_kind(
    ability,
    AbilityKind.RETALIATE_RANGE,
    AbilityKind.RETALIATE_RANGE_END_OF_ROUND
  );
}

export function is_shield(ability: Ability): boolean {
  return is_kind(ability, AbilityKind.SHIELD, AbilityKind.SHIELD_END_OF_ROUND);
}

export function is_shield_eor(ability: Ability): boolean {
  return ability && AbilityKind.SHIELD_END_OF_ROUND === ability.kind;
}

export function is_retaliate_eor(ability: Ability): boolean {
  return ability && AbilityKind.RETALIATE_END_OF_ROUND === ability.kind;
}

export function is_immune(ability: Ability): boolean {
  return ability && ability.kind === AbilityKind.CONDITION_IMMUNE;
}

export function is_persist(ability: Ability): boolean {
  return ability && ability.kind === AbilityKind.CONDITION_PERSIST;
}

export function is_condition_effect(ability: Ability): boolean {
  return ability && ability.kind === AbilityKind.CONDITION_EFFECT;
}

export function is_infuse(ability: Ability): boolean {
  if (!ability) return false;
  return ability.kind === AbilityKind.INFUSE;
}

export function is_consume(ability: Ability): boolean {
  return is_kind(
    ability,
    AbilityKind.CONSUME,
    AbilityKind.IF_CONSUME,
    AbilityKind.IF_CONSUME_ONE_OF
  );
}

export function is_if_consume(ability: Ability): boolean {
  return is_kind(ability, AbilityKind.IF_CONSUME, AbilityKind.IF_CONSUME_ONE_OF);
}

export function is_if_condition(ability: Ability): boolean {
  return ability && ability.kind === AbilityKind.IF_HAVE_CONDITION;
}

export function is_if_stat(ability: Ability): boolean {
  return ability && ability.kind === AbilityKind.IF_HAVE_STAT;
}

export function is_if_on_tile(ability: Ability): boolean {
  return ability && ability.kind === AbilityKind.IF_ON_TILE;
}

export function is_if_normal(ability: Ability): boolean {
  return ability && ability.kind === AbilityKind.IF_NORMAL;
}

export function is_if_elite(ability: Ability): boolean {
  return ability && ability.kind === AbilityKind.IF_ELITE;
}

export function is_perform(ability: Ability) {
  return ability && ability.kind === AbilityKind.IF_PERFORM;
}

export function is_if_active(ability: Ability): boolean {
  return ability && ability.kind === AbilityKind.IF_ACTIVE;
}

export function is_if_expr(ability: Ability): boolean {
  return ability && ability.kind === AbilityKind.IF_EXPR;
}

export function is_range(ability: HasAbilityKind) {
  return ability && ability.kind === AbilityKind.RANGE;
}

export function is_attack(ability: HasAbilityKind) {
  return ability && _ability_kind_info[ability.kind].parent === AbilityKind.ATTACK;
}

export function is_pierce(ability: HasAbilityKind) {
  return ability && ability.kind === AbilityKind.PIERCE;
}

export function is_text(ability: HasAbilityKind) {
  return ability && ability.kind === AbilityKind.TEXT;
}

export function is_heal(ability: HasAbilityKind) {
  return ability && ability.kind === AbilityKind.HEAL;
}

export function is_damage(ability: HasAbilityKind) {
  return ability && _ability_kind_info[ability.kind].parent === AbilityKind.DAMAGE;
}

export function is_loot(ability: HasAbilityKind) {
  return ability && ability.kind === AbilityKind.LOOT;
}

export function is_trap_ability(ability: HasAbilityKind) {
  return is_kind(ability, AbilityKind.CREATE_TRAP, AbilityKind.DISARM_TRAP);
}

export function is_create_trap(ability: HasAbilityKind) {
  return ability && ability.kind === AbilityKind.CREATE_TRAP;
}

export function is_disarm_trap(ability: HasAbilityKind) {
  return ability && ability.kind === AbilityKind.DISARM_TRAP;
}

export function is_create_overlay(ability: HasAbilityKind) {
  return ability.kind === AbilityKind.CREATE_OVERLAY;
}

export function is_destroy(ability: HasAbilityKind) {
  return ability.kind === AbilityKind.DESTROY;
}

export function is_stat_ability(ability: HasAbilityKind) {
  return ability && ability.kind === AbilityKind.STAT;
}

export function is_cycle(ability: HasAbilityKind) {
  return is_kind(
    ability,
    AbilityKind.CYCLE_BEGIN,
    AbilityKind.CYCLE_END,
    AbilityKind.CYCLE_ADD
  );
}

export function is_move(ability: HasAbilityKind) {
  return ability && _ability_kind_info[ability.kind].parent === AbilityKind.MOVE;
}

export function is_fly(ability: Ability) {
  return ability && ability.kind === AbilityKind.FLY;
}

export function is_card_image(ability: Ability) {
  return ability && ability.kind === AbilityKind.CARD_IMAGE;
}

export function is_kill(ability: HasAbilityKind) {
  return ability && ability.kind === AbilityKind.KILL;
}

export function is_transfer(ability: HasAbilityKind) {
  return ability && ability.kind === AbilityKind.TRANSFER;
}

export function is_xp(ability: Ability): boolean {
  return ability && ability.kind === AbilityKind.XP;
}

export function is_special_attack(ability: Ability) {
  return is_kind(ability, AbilityKind.SPECIAL_1, AbilityKind.SPECIAL_2);
}

export function is_summon_ability(ability: HasAbilityKind) {
  return ability && _ability_kind_info[ability.kind].parent === AbilityKind.SUMMON;
}

export function is_spawn_ability(ability: Ability) {
  return ability && ability.kind === AbilityKind.SPAWN;
}

export function is_immune_same(ability: Ability) {
  // Assuming immunity is always a base stat and for monsters always sets both.
  // So null means it is not a monster and the same by definition.
  if (ability.conditions_elite == null) return true;
  return (
    ability.conditions.length === ability.conditions_elite.length &&
    ability.conditions.every((id, i) => ability.conditions_elite[i] === id)
  );
}

export function find_move_icon(abilities: Ability[]): string {
  const move = abilities?.find((a) => a.kind === AbilityKind.MOVE);
  return move ? ability_icon(move) : 'move';
}

export function move_icon(kind: AbilityKind): string {
  const info = _ability_kind_info[kind];
  return info.icon || info.name;
}

// Return an array with each bit separated for use with MultiSelect
export function factions_array(flags: number): number[] {
  let factions: number[] = [];
  for (let i = 0; i < 32; i++) {
    const one_mask = 1 << i;
    if (flags & one_mask) factions.push(one_mask);
    if (flags < one_mask) break;
  }
  return factions;
}
