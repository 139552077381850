import type { Application } from './application.js';
import type { FsImage } from './fs_image.js';
import { FsImageLayout } from './fs_image.js';
import type { Hex, Point } from './hex.js';
import type { Ability, Action } from './stats.js';
import type { AbilityV2 } from './stats_v2.js';
import type { HashMap } from './utility.js';
import type { ConditionState } from './condition_info.js';

// Some info_ids.
export const CHEST_H_ID = 2598;
export const CHEST_ID = 2599;
export const COIN_ID = 2600;
export const FH_LOOT_ID = 2850;
export const FH_CHEST_ID = 2809;
export const ICE_WRAITH_ID = 164;
export const NUMBER_BACK_ID = 3525;
export const UNHEALTHY_MIXTURE_ID = 1949;
export const DEADLY_MIXTURE_ID = 1970;
export const FC_MOC_ID = 193;
export const FC_MOC_DECK_ID = 7997;
export const TA_EV_DECK_ID = 8084;
export const TA_EVF_DECK_ID = 8085;
export const ENHANCER_ID = 3553;
export const HALL_OF_REVELRY_ID = 3558;
export const CARPENTER_ID = 3561;
export const STABLES_ID = 3562;
export const TOWN_HALL_ID = 3563;
export const RANDOM_DUNGEON_ID = 1092;
export const PYROCLAST_ID = 1623;
export const PYROCLAST_HAZARD_ID = 3317;
export const PYROCLAST_IMMUNE_ID = 7607;
export const HIVE_ID = 1619;
export const SHADOW_TOKEN_ID = 3316;
export const TEMPEST_ID = 1512;
export const SPARK_TOKEN_ID = 7830;
export const TRAPPER_ID = 1626;
export const HATCHET_ID = 92;
export const FAVORITE_TOKEN_ID = 8169;
export const WARD_OBSTACLE_ID = 8172;
export const SAND_DEVIL_TRAP_ID = 8171;

export interface SimpleGameInfo {
  game_id: number;
  base_game_id: number;
  kind: string;
  abbr: string;
  name: string;
}

export const GLOOMHAVEN: SimpleGameInfo = {
  game_id: 1,
  base_game_id: 1,
  kind: 'base',
  abbr: 'GH',
  name: 'Gloomhaven',
};
export const FORGOTTEN_CIRCLES: SimpleGameInfo = {
  game_id: 3,
  base_game_id: 1,
  kind: 'expansion',
  abbr: 'FC',
  name: 'Forgotten Circles',
};
export const INTO_THE_UNKNOWN: SimpleGameInfo = {
  game_id: 718,
  base_game_id: 1,
  kind: 'expansion',
  abbr: 'ItU',
  name: 'Into the Unknown',
};
export const CAPITAL_INTRIGUE: SimpleGameInfo = {
  game_id: 719,
  base_game_id: 1,
  kind: 'expansion',
  abbr: 'CI',
  name: 'Capital Intrigue',
};
export const JAWS_OF_THE_LION: SimpleGameInfo = {
  game_id: 4,
  base_game_id: 4,
  kind: 'base',
  abbr: 'JL',
  name: 'Jaws of the Lion',
};
export const FROSTHAVEN: SimpleGameInfo = {
  game_id: 5,
  base_game_id: 5,
  kind: 'base',
  abbr: 'FH',
  name: 'Frosthaven',
};
export const CRIMSON_SCALES: SimpleGameInfo = {
  game_id: 6,
  base_game_id: 6,
  kind: 'base',
  abbr: 'CS',
  name: 'The Crimson Scales',
};
export const TRAIL_OF_ASHES: SimpleGameInfo = {
  game_id: 1510,
  base_game_id: 6,
  kind: 'expansion',
  abbr: 'TA',
  name: 'Trail of Ashes',
};
export const ENVELOPE_X: SimpleGameInfo = {
  game_id: 1527,
  base_game_id: 1,
  kind: 'secret',
  abbr: 'EX',
  name: 'Envelope X',
};
export const ENVELOPE_V: SimpleGameInfo = {
  game_id: 1615,
  base_game_id: 6,
  kind: 'secret',
  abbr: 'EV',
  name: 'Envelope V',
};
export const SATIRE: SimpleGameInfo = {
  game_id: 1471,
  base_game_id: 1,
  kind: 'expansion',
  abbr: 'SEBG',
  name: "Satire's Extended Battle Goals",
};
export const SEEKER_OF_XORN: SimpleGameInfo = {
  game_id: 3464,
  base_game_id: 1,
  kind: 'expansion',
  abbr: 'SoX',
  name: 'Seeker of Xorn',
};
export const SKULLS_IN_THE_SNOW: SimpleGameInfo = {
  game_id: 8052,
  base_game_id: 5,
  kind: 'expansion',
  abbr: 'SS',
  name: 'Skulls in the Snow',
};
export const BUTTONS_AND_BUGS: SimpleGameInfo = {
  game_id: 8089,
  base_game_id: 8089,
  kind: 'base',
  abbr: 'BNB',
  name: 'Buttons & Bugs',
};

// A global copy of the basic game info. They will be displayed in this order.
const _game_info = new Map<number, SimpleGameInfo>([
  [GLOOMHAVEN.game_id, GLOOMHAVEN],
  [FORGOTTEN_CIRCLES.game_id, FORGOTTEN_CIRCLES],
  [INTO_THE_UNKNOWN.game_id, INTO_THE_UNKNOWN],
  [CAPITAL_INTRIGUE.game_id, CAPITAL_INTRIGUE],
  [SEEKER_OF_XORN.game_id, SEEKER_OF_XORN],
  [JAWS_OF_THE_LION.game_id, JAWS_OF_THE_LION],
  [FROSTHAVEN.game_id, FROSTHAVEN],
  [SKULLS_IN_THE_SNOW.game_id, SKULLS_IN_THE_SNOW],
  [CRIMSON_SCALES.game_id, CRIMSON_SCALES],
  [TRAIL_OF_ASHES.game_id, TRAIL_OF_ASHES],
  [SATIRE.game_id, SATIRE],
  [ENVELOPE_X.game_id, ENVELOPE_X],
  [ENVELOPE_V.game_id, ENVELOPE_V],
]);

export function valid_game(game_id: number): boolean {
  return _game_info.has(game_id);
}

export function safe_games(enabled_games: Set<number>): SimpleGameInfo[] {
  return Array.from(_game_info.values()).filter((g) => enabled_games.has(g.game_id));
}

export function box_games(): SimpleGameInfo[] {
  return Array.from(_game_info.values()).filter((g) => g.kind !== 'secret');
}

export function base_games(): SimpleGameInfo[] {
  return Array.from(_game_info.values()).filter((g) => g.kind === 'base');
}

export function game_name(id: number): string {
  return _game_info.get(id)?.name ?? String(id);
}

export function game_abbr(id: number): string {
  return _game_info.get(id)?.abbr ?? String(id);
}

export type InfoEditor = {
  application: Application;
  rows: InfoRecord[];
};

export type InfoRecord = {
  record_id: number;
  application_id: number;
  game_id: number;
  info_kind: InfoKind;
  info_version: number;
  info_id: number;
  info: any;
};

export type UserInfo = {
  record_id: number;
  uuid: string;
  game_id: number;
  info_kind: InfoKind;
  info_id: number;
  info: any;
};

export enum InfoKind {
  NONE = 0,
  GAME = 1,
  CLASS = 2,
  MONSTER = 3,
  BOSS = 4,
  SUMMON = 5,
  OBJECTIVE = 6,
  MONSTER_DECK = 7,
  ITEM = 8,
  SCENARIO = 9,
  CITY_EVENT = 10,
  ROAD_EVENT = 11,
  QUEST = 12,
  GLOOMHAVEN = 13,
  SOLO = 14,
  COMMUNITY = 15,
  RANDOM_DUNGEON = 16,
  RANDOM_ROOM = 17,
  RANDOM_MONSTER = 18,
  NAMED_MONSTER = 19,
  RIFT_EVENT = 20,
  BATTLE_GOAL = 21,
  TREASURE = 22,
  ACHIEVEMENT = 23,
  OUTPOST_SUMMER_EVENT = 24,
  OUTPOST_WINTER_EVENT = 25,
  ROAD_SUMMER_EVENT = 26,
  ROAD_WINTER_EVENT = 27,
  BOAT_EVENT = 28,
  IMAGE = 29,
  SECTION = 30,
  LOOT_CARD = 31,
  BOOK = 32,
  BUILDING = 33,
  MODIFIER_CARD = 34,
  ABILITY_CARD = 35,
  PERK_REMINDER = 36,
  TABLE = 37,
  ENVELOPE = 38,
  RANDOM_SCENARIO = 39,
  CHALLENGE_CARD = 40,
  TRIAL_CARD = 41,
  PET_CARD = 42,
}

export interface InfoKindInfo {
  info_kind: InfoKind;
  name: string;
  abbr?: string;
  hidden?: boolean;
}

export const _info_kind_info: InfoKindInfo[] = [
  { info_kind: InfoKind.NONE, name: 'None' },
  { info_kind: InfoKind.GAME, name: 'Game' },
  { info_kind: InfoKind.CLASS, name: 'Class' },
  { info_kind: InfoKind.MONSTER, name: 'Monster' },
  { info_kind: InfoKind.BOSS, name: 'Boss' },
  { info_kind: InfoKind.SUMMON, name: 'Summon' },
  { info_kind: InfoKind.OBJECTIVE, name: 'Objective' },
  { info_kind: InfoKind.MONSTER_DECK, name: 'Monster Deck' },
  { info_kind: InfoKind.ITEM, name: 'Item' },
  { info_kind: InfoKind.SCENARIO, name: 'Scenario' },
  { info_kind: InfoKind.CITY_EVENT, name: 'City Event', abbr: 'C' },
  { info_kind: InfoKind.ROAD_EVENT, name: 'Road Event', abbr: 'R' },
  { info_kind: InfoKind.QUEST, name: 'Personal Quest' },
  { info_kind: InfoKind.GLOOMHAVEN, name: 'Gloomhaven (link)' },
  { info_kind: InfoKind.SOLO, name: 'Solo Scenario' },
  { info_kind: InfoKind.COMMUNITY, name: 'Community Scen.' },
  { info_kind: InfoKind.RANDOM_DUNGEON, name: 'Random Dungeon Scen.' },
  { info_kind: InfoKind.RANDOM_ROOM, name: 'Random Dungeon Room' },
  { info_kind: InfoKind.RANDOM_MONSTER, name: 'Random Dungeon Monster' },
  { info_kind: InfoKind.NAMED_MONSTER, name: 'Named Monster' },
  { info_kind: InfoKind.RIFT_EVENT, name: 'Rift Event', abbr: 'RF' },
  { info_kind: InfoKind.BATTLE_GOAL, name: 'Battle Goal' },
  { info_kind: InfoKind.TREASURE, name: 'Treasure' },
  { info_kind: InfoKind.ACHIEVEMENT, name: 'Achievement' },
  { info_kind: InfoKind.OUTPOST_SUMMER_EVENT, name: 'Summer Outpost Event', abbr: 'SO' },
  { info_kind: InfoKind.OUTPOST_WINTER_EVENT, name: 'Winter Outpost Event', abbr: 'WO' },
  { info_kind: InfoKind.ROAD_SUMMER_EVENT, name: 'Summer Road Event', abbr: 'SR' },
  { info_kind: InfoKind.ROAD_WINTER_EVENT, name: 'Winter Road Event', abbr: 'WR' },
  { info_kind: InfoKind.BOAT_EVENT, name: 'Boat Event', hidden: true, abbr: 'B' },
  { info_kind: InfoKind.IMAGE, name: 'Map Images' },
  { info_kind: InfoKind.SECTION, name: 'Section' },
  { info_kind: InfoKind.LOOT_CARD, name: 'Loot Card' },
  { info_kind: InfoKind.BOOK, name: 'Book' },
  { info_kind: InfoKind.BUILDING, name: 'Building' },
  { info_kind: InfoKind.MODIFIER_CARD, name: 'Attack Modifier Card' },
  { info_kind: InfoKind.ABILITY_CARD, name: 'Ability Card' },
  { info_kind: InfoKind.PERK_REMINDER, name: 'Perk Reminder' },
  { info_kind: InfoKind.TABLE, name: 'Table' },
  { info_kind: InfoKind.ENVELOPE, name: 'Envelope' },
  { info_kind: InfoKind.RANDOM_SCENARIO, name: 'Random Scenario' },
  { info_kind: InfoKind.CHALLENGE_CARD, name: 'Challenge Card' },
  { info_kind: InfoKind.TRIAL_CARD, name: 'Trial Card' },
  { info_kind: InfoKind.PET_CARD, name: 'Pet Card' },
];

export interface BasicInfo {
  info_id: number;
  info_kind: InfoKind;
  game_id: number;
  name: string;
  object_code: string; // for backwards compat
  lock_kind: LockKind;
  source_ids?: number[]; // Which info unlocks this one
  // When a card has a replacement, but the original is still an option:
  replacement_info_id?: number;
}

export enum LockKind {
  NONE = 0,
  START = 1,
  HIDDEN = 2, // hide from unlock screens until normal game event reveals it
  SECRET = 3, // require some kind of password to unlock
}

export interface GameInfo extends BasicInfo {
  base_game: string;
  expansion: string;
  base_game_id: number;
  features: string[];
  item_supplies: ItemSupplyKind[];
  town_guard_perks?: ClassPerk[];
  tables: HashMap<number>;
  init_actions?: Action[]; // mostly for expansions
  world_map?: WorldMap;
  forteller_href?: string;
}

export interface WorldMap {
  path: string;
  width: number;
  height: number;
  scale: number; // to make the stickers match the size
}

export const game_features = [
  'Campaign scenarios',
  'Random side scenarios',
  'Random dungeons',
  'Reputation',
  'Prosperity',
  'Donations',
  'City events',
  'Road events',
  'Rift events',
  'Personal quests',
  'Special conditions',
  'Global achievements',
  'Party achievements',
  'Town records',
  'Prosperity items',
  'Include base game items',
  'Milestones',
  'Outpost events',
  'Boat events',
  'Party goals',
  'Campaign sheet',
  'Campaign stickers',
  'Morale',
  'Inspiration',
  'Resources',
  'Buildings',
  'Campaign calendar',
  'Shared supply',
  'Soldiers',
  'Defense',
  'Town guard perks',
  'Item blueprints',
  'Loot cards',
  'Random items',
  'Sections',
];

export interface FigureInfo extends BasicInfo {
  standee_count: number;
  portrait: FsImage;
  flying?: boolean;
  named_base_id?: number;
  image_info_id?: number; // for multi-hex figures (objectives)
}

export interface MobInfo extends FigureInfo {
  base_stats_v3: Ability[][]; // array of stats/abilities per level
  monster_deck_id: number;
  stat_card: FsImage[]; // per level
  base_health_expr?: string;
}

export interface MobInfoCompat extends MobInfo {
  base_stats_v2?: AbilityV2[][]; // array of stats/abilities per level
}

export type ClassPerk = {
  description: string;
  count: number;
  effect: Action[];
  actions?: Action[];
};

export enum AbilityCardLevel {
  OFFSET = 8,
  V = -8,
  MILESTONE = -7,
  P = -6,
  M = -5,
  REF = -4,
  A = -3,
  B = -2,
  BACK = -1,
  X = 0,
  _1,
  _2,
  _3,
  _4,
  _5,
  _6,
  _7,
  _8,
  _9,
}

export const card_level_abbr = 'VMPMRABbX123456789';

export enum AbilityCardStatus {
  NONE,
  ACTIVE_PERSISTENT,
  ACTIVE_ROUND,
  SELECT,
  HAND,
  DISCARD,
  LOST,
  REMOVE,
  SUPPLY,
  ITEM,
  MISC,
  RANDOM,
  SENTINEL,
}

export const ability_card_status_name = [
  'none',
  'persistent',
  'round',
  'select',
  'hand',
  'discard',
  'lost',
  'remove',
  'supply',
  'item',
  'misc_card',
  'random_card',
  'sentinel',
];

export const ability_card_statuses = [
  AbilityCardStatus.ACTIVE_PERSISTENT,
  AbilityCardStatus.ACTIVE_ROUND,
  AbilityCardStatus.SELECT,
  AbilityCardStatus.HAND,
  AbilityCardStatus.DISCARD,
  AbilityCardStatus.LOST,
  AbilityCardStatus.REMOVE,
  AbilityCardStatus.SUPPLY,
  AbilityCardStatus.ITEM,
  AbilityCardStatus.MISC,
  AbilityCardStatus.RANDOM,
];

export interface AbilityCardInfo extends BasicInfo {
  class_id: number;
  level: AbilityCardLevel; // 0 is X.
  image: FsImage;
  slots: EnhancementSlot[];
  initiative: number;
  initiative2?: number;
  top_use_slots?: number[];
  bottom_use_slots?: number[];
  actions?: AbilityCardAction[];
  top_abilities_v3?: Ability[];
  bottom_abilities_v3?: Ability[];
  summon_info_ids?: number[];
}

export interface AbilityCardInfoCompat extends AbilityCardInfo {
  top_abilities?: AbilityV2[];
  bottom_abilities?: AbilityV2[];
}

export interface AbilityCardAction {
  position: string;
  card_status: AbilityCardStatus;
  active_final: AbilityCardStatus;
  token_points?: Point[];
}

export type EnhancementSlot = {
  position: string; // top or bottom
  slot: string;
  slot_index: number;
  multiple_targets?: boolean;
  lost?: boolean;
  persistent?: boolean;
  token_point?: Point;
};

export enum ModifierKind {
  NONE = 0,
  STANDARD = 1,
  PERK = 2,
  MINUS_ONE = 3,
  BLESS = 4,
  PLAYER_CURSE = 5,
  MONSTER_CURSE = 6,
  MONSTER_STANDARD = 7,
  OAKS_GIFT_ROLLING = 8,
  OAKS_GIFT_2X = 9,
  EMPOWER_RUINMAW = 10,
  HONORED_1 = 11,
  HONORED_2 = 12,
  HONORED_3 = 13,
  HONORED_4 = 14,
  EMPOWER_INCARNATE = 15,
  ENFEEBLE_INCARNATE = 16,
  ALLY_STANDARD = 17,
  TOWN_GUARD_STANDARD = 18,
  TOWN_GUARD_PERK = 19,
  MINUS_TWO = 20,
  PLUS_ZERO = 21,
  TOWN_GUARD_OTHER = 22,
}

export const modifier_kind_order: ModifierKind[] = [
  ModifierKind.BLESS,
  ModifierKind.PLAYER_CURSE,
  ModifierKind.MONSTER_CURSE,
  ModifierKind.MINUS_ONE,
  ModifierKind.MINUS_TWO,
  ModifierKind.PLUS_ZERO,
  ModifierKind.OAKS_GIFT_ROLLING,
  ModifierKind.OAKS_GIFT_2X,
  ModifierKind.HONORED_1,
  ModifierKind.HONORED_2,
  ModifierKind.HONORED_3,
  ModifierKind.HONORED_4,
  ModifierKind.EMPOWER_RUINMAW,
  ModifierKind.EMPOWER_INCARNATE,
  ModifierKind.ENFEEBLE_INCARNATE,
  ModifierKind.STANDARD,
  ModifierKind.PERK,
  ModifierKind.MONSTER_STANDARD,
  ModifierKind.ALLY_STANDARD,
  ModifierKind.TOWN_GUARD_STANDARD,
  ModifierKind.TOWN_GUARD_OTHER,
  ModifierKind.TOWN_GUARD_PERK,
  ModifierKind.NONE,
];

export enum ModifierEffect {
  NONE = 0,
  PLUS_ZERO = 1,
  PLUS_ONE = 2,
  PLUS_TWO = 3,
  MINUS_ONE = 4,
  MINUS_TWO = 5,
  TIMES_TWO = 6,
  NULL = 7,
  PLUS_TEN = 8,
  PLUS_TWENTY = 9,
  PLUS_THIRTY = 10,
  MINUS_TEN = 11,
  MINUS_TWENTY = 12,
  SUCCESS = 13,
  WRECK = 14,
  CUSTOM = 15,
  CUSTOM_ECLIPSE = 16,
  CUSTOM_TEMPEST = 17,
}

export interface ModifierCard {
  index?: number;
  info_id: number;
  kind: ModifierKind;
  effect: ModifierEffect;
  abilities_v3: Ability[];
  shuffle?: boolean;
  rolling?: boolean;
  image: FsImage;
}

export interface ModifierCardCompat extends ModifierCard {
  abilities_v2: AbilityV2[];
}

// Include this
export const monster_back: ModifierCardInfo = {
  info_id: 0,
  info_kind: InfoKind.MODIFIER_CARD,
  game_id: 0,
  object_code: 'monster-back',
  name: 'monster-back',
  lock_kind: LockKind.NONE,
  index: 0,
  kind: ModifierKind.NONE,
  effect: ModifierEffect.NONE,
  abilities_v3: [],
  image: {
    layout: FsImageLayout.ATTACK_MODIFIER,
    value: 'attack_modifiers/monster/back.webp',
  },
};

export const town_guard_back: ModifierCardInfo = {
  info_id: 0,
  info_kind: InfoKind.MODIFIER_CARD,
  game_id: 0,
  object_code: 'town-guard-back',
  name: 'town-guard-back',
  lock_kind: LockKind.NONE,
  index: 0,
  kind: ModifierKind.NONE,
  effect: ModifierEffect.NONE,
  abilities_v3: [],
  image: {
    layout: FsImageLayout.ATTACK_MODIFIER,
    value: 'worldhaven/attack-modifiers/frosthaven/base/town-guard/fh-am-tg-back.webp',
  },
};

export interface ClassMastery {
  index: number;
  description: string;
}

export interface ClassInfo extends FigureInfo {
  health_expr: string[]; // by level
  hand_size: number;
  class_color: string;
  perks: ClassPerk[];
  ability_cards: number[];
  modifier_cards: number[];
  sheet: FsImage;
  mat_front: FsImage;
  mat_back: FsImage;
  milestone?: FsImage;
  masteries?: ClassMastery[];
  traits?: string[];
  crossover_sheet?: FsImage;
  crossover_perks?: ClassPerk[];
  crossover_masteries?: ClassMastery[];
  unlock?: Action[];
  retire?: Action[];
}

// Info for figures other than characters.
export interface MonsterInfo extends MobInfo {
  health_expr: string[][]; // by level then variant
  description: string;
  difficulty: number;
  spoiler: string;
}

export interface MonsterInfoCompat extends MobInfoCompat {
  health_expr: string[][]; // by level then variant
  description: string;
  difficulty: number;
  spoiler: string;
}

export interface NamedMonsterInfo extends BasicInfo {
  monster_id: number;
  monster_deck_id: number;
  level_expr: string;
  health_expr: string;
}

export interface BossInfo extends MobInfo {
  health_expr: string[]; // by level
  special_attacks_v3: Ability[][]; // boss specials
}

export interface BossInfoCompat extends MobInfoCompat {
  health_expr: string[]; // by level
  special_attacks_v3: Ability[][]; // boss specials
  special_attacks_v2: AbilityV2[][]; // boss specials
}

export interface ObjectiveInfo extends FigureInfo {
  health_expr: string;
  abilities_v3: Ability[];
  initiative: number;
}

export interface ObjectiveInfoCompat extends ObjectiveInfo {
  abilities_v2: AbilityV2[];
}

export interface SummonInfo extends FigureInfo {
  summoner_info_kind: InfoKind;
  summoner_info_id: number;
  card_id: number;
  card_level: number;
  health_expr: string;
  abilities_v3: Ability[];
  stat_card: FsImage;
  standees?: string[];
}

export interface SummonInfoCompat extends SummonInfo {
  abilities_v2: AbilityV2[];
}

export interface MonsterAbilityCard {
  name: string;
  initiative: number;
  shuffle: boolean;
  abilities_v3: Ability[];
  image: FsImage;
}

export interface MonsterAbilityCardCompat extends MonsterAbilityCard {
  abilities_v2: AbilityV2[];
}

export interface MonsterDeckInfo extends BasicInfo {
  card_back: FsImage;
  cards: MonsterAbilityCard[];
}

export interface MonsterDeckInfoCompat extends BasicInfo {
  card_back: FsImage;
  cards: MonsterAbilityCardCompat[];
}

export enum EquipSlot {
  NONE,
  ONE_HAND,
  TWO_HANDS,
  BODY,
  HEAD,
  LEGS,
  SMALL_ITEM,
}

export enum ItemUsage {
  NONE,
  PERSISTENT,
  SPENT,
  CONSUMED,
  FLIP,
}

export enum ItemSupplyKind {
  NONE = 0,
  PURCHASE = 1,
  RANDOM_DESIGN = 2,
  SOLO = 3,
  RANDOM_ORB = 4,
  RANDOM_STONE = 5,
  CRAFT = 6,
  BREW = 7,
  RANDOM_BLUEPRINT = 8,
  RANDOM_ITEM = 9,
}

export interface ItemSupplyKindInfo {
  kind: ItemSupplyKind;
  name: string;
  deck_kind: number; // 0 - none, 1 - design, 2 - item
}

export const item_supply_kind_info: ItemSupplyKindInfo[] = [
  { kind: ItemSupplyKind.NONE, name: 'None', deck_kind: 0 },
  { kind: ItemSupplyKind.PURCHASE, name: 'Purchase', deck_kind: 0 },
  { kind: ItemSupplyKind.RANDOM_DESIGN, name: 'Random item design', deck_kind: 1 },
  { kind: ItemSupplyKind.SOLO, name: 'Solo', deck_kind: 0 },
  { kind: ItemSupplyKind.RANDOM_ORB, name: 'Random Orb item', deck_kind: 2 },
  { kind: ItemSupplyKind.RANDOM_STONE, name: 'Random Stone item', deck_kind: 2 },
  { kind: ItemSupplyKind.CRAFT, name: 'Craft', deck_kind: 0 },
  { kind: ItemSupplyKind.BREW, name: 'Brew', deck_kind: 0 },
  { kind: ItemSupplyKind.RANDOM_BLUEPRINT, name: 'Random blueprint', deck_kind: 1 },
  { kind: ItemSupplyKind.RANDOM_ITEM, name: 'Random item', deck_kind: 2 },
];

export interface ItemInfo extends BasicInfo {
  item_code: string;
  item_supply_kind: ItemSupplyKind;
  cost: number;
  fh_cost: number[];
  market_count: number;
  equip_slot: EquipSlot;
  usage: ItemUsage;
  use_slots: number;
  cannot_refresh: boolean;
  faq: string;
  image: FsImage;
  back: FsImage;
  token_points?: Point[];
  persistent_effects?: Action[];
  side_effects_v3?: Ability[];
}

export interface ItemInfoCompat extends ItemInfo {
  side_effects?: AbilityV2[];
}

export type EquipSlotInfo = {
  slot: EquipSlot;
  slot_name: string;
  icon: string;
};

export const equip_slot_info = [
  {
    slot: EquipSlot.NONE,
    slot_name: 'None',
    icon: 'none',
  },
  {
    slot: EquipSlot.ONE_HAND,
    slot_name: 'One Hand',
    icon: 'one_hand',
  },
  {
    slot: EquipSlot.TWO_HANDS,
    slot_name: 'Two Hands',
    icon: 'two_hands',
  },
  {
    slot: EquipSlot.BODY,
    slot_name: 'Body',
    icon: 'body',
  },
  {
    slot: EquipSlot.HEAD,
    slot_name: 'Head',
    icon: 'head',
  },
  {
    slot: EquipSlot.LEGS,
    slot_name: 'Legs',
    icon: 'legs',
  },
  {
    slot: EquipSlot.SMALL_ITEM,
    slot_name: 'Small Item',
    icon: 'small_item',
  },
];

export enum SpecialRuleConditionKind {
  NONE = 0,
  ODD_ROUNDS = 1,
  EVEN_ROUNDS = 2,
  EVERY_NTH_ROUND = 3,
  ROUND_LIST = 4,
  MONSTER_TYPE_ALIVE = 5,
  LABEL_UNREVEALED = 6,
  CHARACTER_COUNTS = 7,
  START_AT_ROUND = 8,
  END_AT_ROUND = 9,
  START_AFTER_N_ROUNDS = 10,
  N_TIMES = 11,
  FIGURE_ALIVE = 12,
  EXPR = 13,
}

export interface SpecialRuleCondition {
  kind: SpecialRuleConditionKind;
  character_counts?: number[];
  rounds?: number[];
  round?: number;
  group_info_id?: number;
  variant?: string; // falsy, normal, elite
  label?: string;
  standee?: string;
  n_times?: number;
  n_rounds?: number;
  nth_round?: number;
  expr?: string;
}

export type SpecialRule = {
  heading: string; // What to display in UI.
  revealed_tile: string; // This rule is active if this tile is revealed.
  unrevealed_tile?: string; // Also only active if this tile is not revealed.
  group_exists?: string; // Also only active if this group exists
  when: string[]; // start_of_round, end_of_round
  actions: Action[];
  display_expr?: string; // return non-0 to display rule in UI
  round_expr?: string; // return non-0 when applicable
  display?: boolean; // set to false for rules that should never be shown
  conditions?: SpecialRuleCondition[];
  show_in_ui?: boolean; // updated based on its current state
  reveal_round?: number;
  last_perform_round?: number;
  perform_count?: number;
  cycle_actions?: number;
};

export enum Resource {
  NONE = 0,
  HIDE = 1,
  LUMBER = 2,
  METAL = 3,
  ARROWVINE = 4,
  AXENUT = 5,
  CORPSECAP = 6,
  FLAMEFRUIT = 7,
  ROCKROOT = 8,
  SNOWTHISTLE = 9,
  ANY_MATERIAL = 10,
  ANY_HERB = 11,
  ANY_RESOURCE = 12,
  LAST = 13,
}

export enum ResourceFlag {
  NONE = 0,
  MATERIAL = 1,
  HERB = 2,
  ANY = 4,
}

export interface ResourceInfo {
  kind: Resource;
  name: string;
  icon: string;
  flags: number;
}

export const resource_kinds = [
  Resource.LUMBER,
  Resource.METAL,
  Resource.HIDE,
  Resource.ARROWVINE,
  Resource.AXENUT,
  Resource.CORPSECAP,
  Resource.FLAMEFRUIT,
  Resource.ROCKROOT,
  Resource.SNOWTHISTLE,
];
export const material_kinds = [Resource.LUMBER, Resource.METAL, Resource.HIDE];
export const herb_kinds = [
  Resource.ARROWVINE,
  Resource.AXENUT,
  Resource.CORPSECAP,
  Resource.FLAMEFRUIT,
  Resource.ROCKROOT,
  Resource.SNOWTHISTLE,
];

export const resource_info: ResourceInfo[] = [
  { kind: Resource.NONE, name: 'None', icon: 'none', flags: 0 },
  { kind: Resource.HIDE, name: 'Hide', icon: 'hide', flags: ResourceFlag.MATERIAL },
  { kind: Resource.LUMBER, name: 'Lumber', icon: 'lumber', flags: ResourceFlag.MATERIAL },
  { kind: Resource.METAL, name: 'Metal', icon: 'metal', flags: ResourceFlag.MATERIAL },
  {
    kind: Resource.ARROWVINE,
    name: 'Arrowvine',
    icon: 'arrowvine',
    flags: ResourceFlag.HERB,
  },
  { kind: Resource.AXENUT, name: 'Axenut', icon: 'axenut', flags: ResourceFlag.HERB },
  {
    kind: Resource.CORPSECAP,
    name: 'Corpsecap',
    icon: 'corpsecap',
    flags: ResourceFlag.HERB,
  },
  {
    kind: Resource.FLAMEFRUIT,
    name: 'Flamefruit',
    icon: 'flamefruit',
    flags: ResourceFlag.HERB,
  },
  {
    kind: Resource.ROCKROOT,
    name: 'Rockroot',
    icon: 'rockroot',
    flags: ResourceFlag.HERB,
  },
  {
    kind: Resource.SNOWTHISTLE,
    name: 'Snowthistle',
    icon: 'snowthistle',
    flags: ResourceFlag.HERB,
  },
  {
    kind: Resource.ANY_MATERIAL,
    name: 'Any material',
    icon: 'material',
    flags: ResourceFlag.MATERIAL | ResourceFlag.ANY,
  },
  {
    kind: Resource.ANY_HERB,
    name: 'Any herb',
    icon: 'herb',
    flags: ResourceFlag.HERB | ResourceFlag.ANY,
  },
  {
    kind: Resource.ANY_RESOURCE,
    name: 'Any resource',
    icon: 'resource',
    flags: ResourceFlag.MATERIAL | ResourceFlag.HERB | ResourceFlag.ANY,
  },
];

export function find_resource_kind(code: string) {
  const kind = Number(code);
  if (!isNaN(kind) && resource_info[kind]) return kind;
  return resource_info.find((info) => info.icon === code)?.kind ?? Resource.NONE;
}

export enum LootKind {
  NONE = 0,
  HIDE = 1,
  LUMBER = 2,
  METAL = 3,
  ARROWVINE = 4,
  AXENUT = 5,
  CORPSECAP = 6,
  FLAMEFRUIT = 7,
  ROCKROOT = 8,
  SNOWTHISTLE = 9,
  MONEY = 10,
  RANDOM_ITEM = 11,
  CARD_1418 = 12,
  CARD_1419 = 13,
}

export interface LootKindInfo {
  kind: LootKind;
  name: string;
  icon: string;
}

export const loot_kind_info = [
  { kind: LootKind.NONE, name: 'None', icon: 'none' },
  { kind: LootKind.HIDE, name: 'Hide', icon: 'hide' },
  { kind: LootKind.LUMBER, name: 'Lumber', icon: 'lumber' },
  { kind: LootKind.METAL, name: 'Metal', icon: 'metal' },
  { kind: LootKind.ARROWVINE, name: 'Arrowvine', icon: 'arrowvine' },
  { kind: LootKind.AXENUT, name: 'Axenut', icon: 'axenut' },
  { kind: LootKind.CORPSECAP, name: 'Corpsecap', icon: 'corpsecap' },
  { kind: LootKind.FLAMEFRUIT, name: 'Flamefruit', icon: 'flamefruit' },
  { kind: LootKind.ROCKROOT, name: 'Rockroot', icon: 'rockroot' },
  { kind: LootKind.SNOWTHISTLE, name: 'Snowthistle', icon: 'snowthistle' },
  { kind: LootKind.MONEY, name: 'Money', icon: 'money' },
  { kind: LootKind.RANDOM_ITEM, name: 'Random Item', icon: 'random_item' },
  { kind: LootKind.CARD_1418, name: 'Card 1418', icon: 'money' },
  { kind: LootKind.CARD_1419, name: 'Card 1419', icon: 'money' },
];

export interface Forteller {
  label: string;
  href: string;
}

export interface ScenarioInfo extends BasicInfo {
  map_coordinate: string;
  region_name: string;
  goal: string;
  requirements_expr: string;
  requirements_text: string;
  linked_info_ids: number[];
  force_linked_info_ids?: number[];
  conclusion: Action[];
  enemy_info_ids: number[];
  treasures?: number[];
  traps?: Action[];
  hazards?: number[];
  special_rules?: SpecialRule[];
  // When manually unlocking, some actions might be needed.
  unlock?: Action[];
  has_map: boolean;
  hide_unrevealed?: boolean;
  tiles: string[];
  sections: ScenarioSectionInfo[];
  pages: ScenarioPageInfo[];
  complexity?: number;
  loot_deck_counts?: number[]; // counts indexed by LootKind
  modifier_codes?: HashMap<string>; // indexed by enemy_info_ids
  enemy_group?: HashMap<number>; // a condition_id indexed by enemy_info_ids
  credits?: string;
  sticker?: Sticker;
  level?: number;
  forteller?: Forteller[];
}

export interface Sticker {
  path: string;
  x: number;
  y: number;
  scale: number;
  rotate?: number;
}

// Standard labels used in introduction pages.
export const introduction_labels: string[] = [
  'Introduction',
  'Map',
  'Tiles',
  'Scenario setup',
  'Scenario key',
  'Loot',
  'Map layout',
];

export interface Rect {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface LabelRect extends Rect {
  label: string;
}

export interface ScenarioSectionLinkInfo {
  section_index: number;
  requirements_text: string;
}

export interface ScenarioSectionInfo {
  section_index: number;
  label: string;
  tiles: string[];
  open_all_doors?: boolean;
  enemy_info_ids: number[];
  special_rules: SpecialRule[];
  section_links: ScenarioSectionLinkInfo[];
  actions: Action[];
}

export interface PageInfo {
  section_index: number;
  crop: Rect;
  image: FsImage;
  masks: PageMaskInfo[];
}

export interface PageMaskInfo {
  section_id: number;
  mask: Rect;
}

export interface EventInfo extends BasicInfo {
  outcome_a: Action[];
  outcome_b: Action[];
  icons: string[];
  front?: FsImage;
  back?: FsImage;
  lines?: number[];
  label_c?: string; // Set for secret or attack
  outcome_c?: Action[];
  forteller?: Forteller[];
}

export const default_outcome: Action[] = [
  ['choice', '0', 'remove_event'],
  ['choice', '1', 'return_event'],
];

export interface EventKindInfo {
  info_kind: InfoKind;
  event_kind: string;
  name: string;
  brief: string;
  game_ids: number[];
}

export const event_kind_info: EventKindInfo[] = [
  {
    info_kind: InfoKind.CITY_EVENT,
    event_kind: 'city',
    name: 'City event',
    brief: 'City event',
    game_ids: [
      GLOOMHAVEN.game_id,
      FORGOTTEN_CIRCLES.game_id,
      JAWS_OF_THE_LION.game_id,
      CRIMSON_SCALES.game_id,
      TRAIL_OF_ASHES.game_id,
    ],
  },
  {
    info_kind: InfoKind.ROAD_EVENT,
    event_kind: 'road',
    name: 'Road event',
    brief: 'Road event',
    game_ids: [
      GLOOMHAVEN.game_id,
      FORGOTTEN_CIRCLES.game_id,
      CRIMSON_SCALES.game_id,
      TRAIL_OF_ASHES.game_id,
    ],
  },
  {
    info_kind: InfoKind.RIFT_EVENT,
    event_kind: 'rift',
    name: 'Rift event',
    brief: 'Rift event',
    game_ids: [FORGOTTEN_CIRCLES.game_id],
  },
  {
    info_kind: InfoKind.OUTPOST_SUMMER_EVENT,
    event_kind: 'outpost_summer',
    name: 'Summer outpost event',
    brief: 'Outpost event',
    game_ids: [FROSTHAVEN.game_id],
  },
  {
    info_kind: InfoKind.OUTPOST_WINTER_EVENT,
    event_kind: 'outpost_winter',
    name: 'Winter outpost event',
    brief: 'Outpost event',
    game_ids: [FROSTHAVEN.game_id],
  },
  {
    info_kind: InfoKind.ROAD_SUMMER_EVENT,
    event_kind: 'road_summer',
    name: 'Summer road event',
    brief: 'Road event',
    game_ids: [FROSTHAVEN.game_id],
  },
  {
    info_kind: InfoKind.ROAD_WINTER_EVENT,
    event_kind: 'road_winter',
    name: 'Winter road event',
    brief: 'Road event',
    game_ids: [FROSTHAVEN.game_id],
  },
  {
    info_kind: InfoKind.BOAT_EVENT,
    event_kind: 'boat',
    name: 'Boat event',
    brief: 'Boat event',
    game_ids: [FROSTHAVEN.game_id],
  },
];

export interface QuestInfo extends BasicInfo {
  image: FsImage;
  step_one: Action[];
  retire: Action[];
  notes: string;
}

export interface RandomRoomInfo extends BasicInfo {
  enter_doors: string;
  exit_doors: string;
  minor: string;
  major: string;
  front: FsImage;
  back: FsImage;
}

export interface RandomMonsterInfo extends BasicInfo {
  special_rules?: SpecialRule[];
  traps?: Action[];
  front: FsImage;
}

export interface BattleGoalInfo extends BasicInfo {
  old_goal_id: number; // The old id, now use info_id
  checkmarks: number;
  image: FsImage;
}

export interface TreasureInfo extends BasicInfo {
  old_treasure_id: number; // The old id, now use info_id
  scenario_id: number;
  scenario_ids?: number[];
  description: string;
  actions: Action[];
}

export type AchievementKind =
  | 'party'
  | 'global'
  | 'campaign sticker'
  | 'rule sticker'
  | 'overlay sticker';

export interface AchievementInfo extends BasicInfo {
  achievement_kind: AchievementKind;
  count: number;
  slot?: string;
  sticker?: Sticker;
}

export enum MapItemKind {
  NONE = 0,
  JOTL_MAP = 1,
  TILE = 2,
  CORRIDOR = 3,
  PRESSURE_PLATE = 4,
  TERRAIN = 5, // misc. that should be under most things
  WALL = 6,
  DOOR = 7,
  START = 8,
  DIFFICULT = 9,
  HAZARDOUS = 10,
  ICY = 11,
  OBSTACLE = 12,
  TRAP = 13,
  CHEST = 14,
  TOKEN = 15,
  MONSTER = 16,
  BOSS = 17,
  OBJECTIVE = 18,
  COIN = 19,
  NUMBER_TOKEN = 20,
  WATER = 21,
  WALL_BETWEEN = 22,
  NEGATIVE_HEX = 23,
  // Update shift so it is the size that fits all the kinds. Used to
  // encode MapItemKind into a number with other data.
  SHIFT = 5,
}

export const map_item_kind_names: string[] = [
  'none',
  'jotl_map',
  'tile',
  'corridor',
  'pressure_plate',
  'terrain',
  'wall',
  'door',
  'start',
  'difficult',
  'hazardous',
  'icy',
  'obstacle',
  'trap',
  'chest',
  'token',
  'monster',
  'boss',
  'objective',
  'coin',
  'number_token',
  'water',
  'wall_between',
  'negative_hex',
];

export const map_item_kinds: MapItemKind[] = [
  MapItemKind.NONE,
  MapItemKind.JOTL_MAP,
  MapItemKind.TILE,
  MapItemKind.CORRIDOR,
  MapItemKind.PRESSURE_PLATE,
  MapItemKind.TERRAIN,
  MapItemKind.WALL,
  MapItemKind.DOOR,
  MapItemKind.START,
  MapItemKind.DIFFICULT,
  MapItemKind.HAZARDOUS,
  MapItemKind.ICY,
  MapItemKind.OBSTACLE,
  MapItemKind.TRAP,
  MapItemKind.CHEST,
  MapItemKind.TOKEN,
  MapItemKind.MONSTER,
  MapItemKind.BOSS,
  MapItemKind.OBJECTIVE,
  MapItemKind.COIN,
  MapItemKind.NUMBER_TOKEN,
  MapItemKind.WATER,
  MapItemKind.WALL_BETWEEN,
  MapItemKind.NEGATIVE_HEX,
];

export const map_item_kind_order: number[] = [
  MapItemKind.NONE,
  MapItemKind.JOTL_MAP,
  MapItemKind.TILE,
  MapItemKind.WALL_BETWEEN,
  MapItemKind.CORRIDOR,
  MapItemKind.PRESSURE_PLATE,
  MapItemKind.TERRAIN,
  MapItemKind.WALL,
  MapItemKind.DOOR,
  MapItemKind.START,
  MapItemKind.DIFFICULT,
  MapItemKind.WATER,
  MapItemKind.HAZARDOUS,
  MapItemKind.ICY,
  MapItemKind.NEGATIVE_HEX,
  MapItemKind.OBSTACLE,
  MapItemKind.TRAP,
  MapItemKind.CHEST,
  MapItemKind.COIN,
  MapItemKind.NUMBER_TOKEN,
  MapItemKind.TOKEN,
  MapItemKind.MONSTER,
  MapItemKind.BOSS,
  MapItemKind.OBJECTIVE,
]
  .map((k, i) => ({ k, i }))
  .sort((a, b) => a.k - b.k)
  .map((a) => a.i);

export type MapItem = {
  id: number;
  change_id: number; // Used by UI to trigger render
  scenario_id: number;
  game_id: number;
  info_id: number;
  map_item_kind: MapItemKind;
  tile1: string;
  tile2: string;
  q: number;
  r: number;
  hex_rotate: number;
  variants: string[];
  misc: string;
  size: number;
  variant?: string;
  order?: number;
  conditions?: HashMap<ConditionState>;
};

export interface ImageInfo extends BasicInfo {
  map_item_kind: MapItemKind;
  path: string;
  center_x: number; // center of origin hex
  center_y: number;
  size: number; // Used to scale the image to fit UI size
  rotate: number;
  pixel_width: number;
  pixel_height: number;
  hexes: Hex[];
  alternate_info_id: number; // for doors and numbered tokens
  class_id?: number; // An image used only by some class
  scenario_id?: number; // A special image used only by some scenario
  border?: string; // just for tiles
}

// This is a rect in a page of a section book.
export interface SectionInfo extends BasicInfo {
  actions: Action[];
  book_id: number;
  page_number: number;
  rect: Rect;
  forteller?: Forteller[];
}

export interface LootCardInfo extends BasicInfo {
  loot_kind: LootKind;
  actions: Action[];
  image: FsImage;
}

export const loot_card_back: FsImage = {
  layout: FsImageLayout.LOOT_CARD,
  value: 'worldhaven/loot-deck/frosthaven/fh-loot-deck-back.webp',
};

export enum BookKind {
  NONE = 0,
  SCENARIO = 1,
  SECTION = 2,
  RULE = 3,
}

export interface BookInfo extends BasicInfo {
  book_kind: BookKind;
  base_path: string;
  first_page: number;
  last_page: number;
  page_width: number;
  page_height: number;
}

export interface ScenarioPageInfo {
  book_id: number;
  page_number: number;
  crop: LabelRect;
  hide: LabelRect[];
}

export interface BuildingAction {
  label: string;
  requirement_expr: string;
  actions: Action[];
  cost: Action[];
}

export interface BuildingLevel {
  level: number;
  one_time: Action[];
  actions: BuildingAction[];
  front: FsImage;
  back: FsImage;
  enhance_discounts?: number[];
  sticker?: Sticker;
}

export interface BuildingInfo extends BasicInfo {
  levels: BuildingLevel[];
}

export interface ModifierCardInfo extends BasicInfo {
  index: number;
  kind: ModifierKind;
  effect: ModifierEffect;
  abilities_v3: Ability[];
  shuffle?: boolean;
  rolling?: boolean;
  class_id?: number;
  image: FsImage;
}

export interface ModifierCardInfoCompat extends ModifierCardInfo {
  abilities_v2: AbilityV2[];
}

export interface PerkReminderInfo extends BasicInfo {
  class_id: number;
  image: FsImage;
  back: FsImage;
  persistent_effects?: Action[];
  side_effects_v3?: Ability[];
  usage: ItemUsage;
  use_slots: number;
}

export interface PerkReminderInfoCompat extends PerkReminderInfo {
  side_effects?: AbilityV2[];
}

export interface TableEntry {
  name: string;
  actions: Action[];
}

export interface ProsperityLevelInfo extends TableEntry {
  level: number;
  checkmarks: number;
  first_check: number;
  last_check: number;
}

export interface MoraleEntry extends TableEntry {
  defense: number; // the absolute bonus
}

// For now, tables are all custom and the code is expected to know how to
// interpret them.
export interface TableInfo extends BasicInfo {
  first_index: number;
  table: TableEntry[];
}

export interface ProsperityTableInfo extends BasicInfo {
  first_index: number;
  table: ProsperityLevelInfo[];
}

export interface EnvelopeInfo extends BasicInfo {
  actions: Action[];
}

export interface RandomScenarioInfo extends BasicInfo {
  scenario_id: number;
  actions: Action[];
  image: FsImage;
}

export interface ChallengeCardInfo extends BasicInfo {
  description: string;
  image: FsImage;
}

export interface TrialCardInfo extends BasicInfo {
  description: string;
  image: FsImage;
}

export interface PetCardInfo extends BasicInfo {
  description: string;
  image: FsImage;
  back: FsImage;
}

export enum Favor {
  NONE,
  KNOWLEDGE,
  WEALTH,
  STRATEGY,
  CAPACITY,
  DISCOVERY,
  POTENTIAL,
  SENTINEL,
}

export interface FavorInfo {
  name: string;
  cost: number;
  description: string;
}

export const favor_info: FavorInfo[] = [
  {
    name: 'none',
    cost: 0,
    description: 'none',
  },
  {
    name: 'Knowledge',
    cost: 1,
    description:
      'Each character will gain 3 experience if they achieve their battle goal.',
  },
  {
    name: 'Wealth',
    cost: 2,
    description:
      'Each character will gain +1 gold per coin on their loot cards. (Errata: costs' +
      ' 2 points, not 1)',
  },
  {
    name: 'Strategy',
    cost: 1,
    description:
      'The party chooses a monster ability deck and draws two cards at random,' +
      ' removing one from this scenario and shuffling the other back into the deck.' +
      ' This can only be done once per deck.',
  },
  {
    name: 'Capacity',
    cost: 1,
    description:
      'Each character may bring one extra small item into this scenario, but they must' +
      ' have one unused small item at all times during this scenario.',
  },
  {
    name: 'Discovery',
    cost: 1,
    description:
      'The party chooses a material or herb resource not included in the loot deck for' +
      ' this scenario. Add one random loot card of that type to the loot deck for this' +
      ' scenario. (Add them in the loot deck interface on the play scenario screen.)',
  },
  {
    name: 'Potential',
    cost: 2,
    description:
      'Each character may add one ability card, from among those they have passed over' +
      ' when leveling up, to their pool for this scenario. If they do, they must remove' +
      ' a different card of equal or higher level from their pool for this scenario.' +
      ' (Unchosen level up cards are added to the card interface.)',
  },
];

export function info_kind_names() {
  return _info_kind_info.map((i) => i.name);
}

export function info_kind_name(kind: InfoKind) {
  return _info_kind_info[kind]?.name ?? `kind-${kind}`;
}

export function info_kind_abbr(kind: InfoKind) {
  return _info_kind_info[kind]?.abbr ?? _info_kind_info[kind]?.name ?? `k${kind}`;
}

export function record_name(record: InfoRecord) {
  return `${record.info_id}: ${record.info.name}`;
}

export function info_option_names(kind: InfoKind, info: any) {
  switch (kind) {
    case InfoKind.GAME:
      return [info.base_game, info.expansion];
    default:
      break;
  }
  return [info.name, info.object_code];
}

export interface HasInfoKind {
  info_kind: InfoKind;
}

export function is_room_card(info: HasInfoKind) {
  return info.info_kind === InfoKind.RANDOM_ROOM;
}

export function is_monster_card(info: HasInfoKind) {
  return info.info_kind === InfoKind.RANDOM_MONSTER;
}

export function is_event(info: HasInfoKind) {
  return info && event_kind_info.some((i) => i.info_kind === info.info_kind);
}

export function is_frosthaven_event(info: HasInfoKind) {
  return (
    info.info_kind === InfoKind.OUTPOST_SUMMER_EVENT ||
    info.info_kind === InfoKind.OUTPOST_WINTER_EVENT ||
    info.info_kind === InfoKind.ROAD_SUMMER_EVENT ||
    info.info_kind === InfoKind.ROAD_WINTER_EVENT ||
    info.info_kind === InfoKind.BOAT_EVENT
  );
}

export function is_summer_event(info: HasInfoKind) {
  return (
    info.info_kind === InfoKind.OUTPOST_SUMMER_EVENT ||
    info.info_kind === InfoKind.ROAD_SUMMER_EVENT
  );
}

export function is_winter_event(info: HasInfoKind) {
  return (
    info.info_kind === InfoKind.OUTPOST_WINTER_EVENT ||
    info.info_kind === InfoKind.ROAD_WINTER_EVENT
  );
}

export function event_info_kind(kind: string) {
  return event_kind_info.find((i) => i.event_kind === kind)?.info_kind ?? 0;
}

export function get_event_kind_info(info: HasInfoKind) {
  return info && event_kind_info.find((i) => i.info_kind === info.info_kind);
}

export function is_treasure_in_scenario(info: TreasureInfo, scenario_id: number) {
  return info.scenario_ids
    ? info.scenario_ids.includes(scenario_id)
    : info.scenario_id === scenario_id;
}

export function is_start_info(info: BasicInfo) {
  return info.lock_kind === LockKind.START;
}

export function card_level_name(level: number) {
  if (level <= 0) return ['X', 'BACK', 'B', 'A', 'REF', 'M', 'P', 'M', 'V'][-level];
  return String(level);
}

interface HasItemSupplyKind {
  item_supply_kind: ItemSupplyKind;
}

export function is_purchase_item(item: HasItemSupplyKind) {
  return [
    ItemSupplyKind.PURCHASE,
    ItemSupplyKind.RANDOM_DESIGN,
    ItemSupplyKind.SOLO,
    ItemSupplyKind.RANDOM_ORB,
    ItemSupplyKind.RANDOM_STONE,
    ItemSupplyKind.RANDOM_ITEM,
  ].includes(item.item_supply_kind);
}

export function is_craft_item(item: HasItemSupplyKind) {
  return [ItemSupplyKind.CRAFT, ItemSupplyKind.RANDOM_BLUEPRINT].includes(
    item.item_supply_kind
  );
}

export function is_brew_item(item: HasItemSupplyKind) {
  return ItemSupplyKind.BREW === item.item_supply_kind;
}

export function item_max_slots(info: ItemInfo) {
  return (
    1 + (info.use_slots ? info.use_slots : info.usage === ItemUsage.PERSISTENT ? 0 : 1)
  );
}

// ------------------------------------------------------------------------
// InfoKind utilities

export interface HasInfoKind {
  info_kind: InfoKind;
}

export function is_any_mob(info: HasInfoKind) {
  if (!info) return false;
  return info.info_kind === InfoKind.MONSTER || info.info_kind === InfoKind.BOSS;
}

export function is_any_scenario(info: HasInfoKind) {
  if (!info) return false;
  return (
    info.info_kind === InfoKind.SCENARIO ||
    info.info_kind === InfoKind.SOLO ||
    info.info_kind === InfoKind.COMMUNITY
  );
}

export function is_any_enemy(info: HasInfoKind): boolean {
  return (
    info &&
    (info.info_kind === InfoKind.MONSTER ||
      info.info_kind === InfoKind.BOSS ||
      info.info_kind === InfoKind.OBJECTIVE)
  );
}

export function is_any_figure(info: HasInfoKind): boolean {
  return (
    info &&
    (info.info_kind === InfoKind.MONSTER ||
      info.info_kind === InfoKind.BOSS ||
      info.info_kind === InfoKind.OBJECTIVE ||
      info.info_kind === InfoKind.SUMMON ||
      info.info_kind === InfoKind.CLASS)
  );
}
export function is_character(info: HasInfoKind): boolean {
  return info?.info_kind === InfoKind.CLASS;
}

export function is_monster(info: HasInfoKind): boolean {
  return info?.info_kind === InfoKind.MONSTER;
}

export function is_boss(info: HasInfoKind): boolean {
  return info?.info_kind === InfoKind.BOSS;
}

export function is_objective(info: HasInfoKind): boolean {
  return info?.info_kind === InfoKind.OBJECTIVE;
}

export function is_summon(info: HasInfoKind): boolean {
  return info?.info_kind === InfoKind.SUMMON;
}

export function is_image(info: HasInfoKind): boolean {
  return info?.info_kind === InfoKind.IMAGE;
}

export function map_item_kind_flags_str(flags: number): string {
  const names: string[] = [];
  for (let i = 0; i < map_item_kind_names.length; i++) {
    if (flags & (1 << i)) names.push(map_item_kind_names[i]);
  }
  return names.join(',');
}
