export interface FsImage {
  layout: FsImageLayout;
  value: string;
}

export function fs_image(layout: FsImageLayout, id: number, field: string): FsImage {
  const info = layout_info[layout];
  const value =
    '/public/images/byid/' + id + '.' + field + (info.is_svg ? '.svg' : '.webp');
  return { layout, value };
}

export function fs_item(id: number): FsImage {
  return fs_image(FsImageLayout.ITEM, id, 'image');
}

export function fs_reminder(id: number): FsImage {
  return fs_image(FsImageLayout.PERK_REMINDER, id, 'image');
}

export interface HasClassId {
  class_id: number;
  game_id: number;
}

export function fs_mat(cl: HasClassId, field: string): FsImage {
  return fs_image(
    cl.game_id === 4 ? FsImageLayout.CLASS_MAT_TALL : FsImageLayout.CLASS_MAT,
    cl.class_id,
    'mat_' + field
  );
}

export function fs_sheet(cl: HasClassId, use_crossover?: boolean): FsImage {
  return fs_image(
    FsImageLayout.CLASS_SHEET,
    cl.class_id,
    (use_crossover ? 'crossover_' : '') + 'sheet'
  );
}

export interface HasPortraitClassId {
  class_id: number;
  portrait_layout?: number;
}

export interface HasPortraitInfoId {
  info_id: number;
  portrait_layout?: number;
}

export function fs_portrait(g: HasPortraitClassId): FsImage;
export function fs_portrait(g: HasPortraitInfoId): FsImage;
export function fs_portrait(g: any): FsImage {
  return fs_image(
    g.portrait_layout ?? FsImageLayout.PORTRAIT_TALL,
    g.info_id ?? g.class_id,
    'portrait'
  );
}

export interface HasMonsterDeckId {
  monster_deck_id?: number;
}

export function fs_mdeck(deck: HasMonsterDeckId, index: number): FsImage {
  if (index < 0 || index === null) return fs_mdeck_back(deck);
  return {
    layout:
      deck.monster_deck_id === 7997
        ? FsImageLayout.MONSTER_ABILITY_FULL
        : FsImageLayout.MONSTER_ABILITY,
    value: '/public/images/byid/' + deck.monster_deck_id + '.image.' + index + '.webp',
  };
}

export function fs_mdeck_back(deck: HasMonsterDeckId): FsImage {
  return fs_image(FsImageLayout.MONSTER_ABILITY, deck.monster_deck_id, 'card_back');
}

export function fs_modifier(id: number): FsImage {
  return fs_image(FsImageLayout.ATTACK_MODIFIER, id, 'image');
}

export interface HasInfoId {
  info_id?: number;
}

export function fs_mstat(g: HasInfoId, level: number): FsImage {
  return {
    layout: FsImageLayout.MONSTER_STAT,
    value: '/public/images/byid/sc/' + g.info_id + '.' + level + '.webp',
  };
}

export function fs_card_image(value: number[]) {
  return {
    layout: value[0],
    value:
      '/public/images/byid/' +
      value[1] +
      '.image.' +
      (value.length === 3 ? String(value[2]) + '.' : '') +
      'webp',
  };
}

export interface HasQuestId {
  quest_id?: number;
}

export function fs_quest(detail: HasQuestId): FsImage {
  return fs_image(FsImageLayout.QUEST, detail.quest_id, 'image');
}

export interface HasTrialId {
  trial_id?: number;
}

export function fs_trial(detail: HasTrialId): FsImage {
  return fs_image(FsImageLayout.TRIAL_CARD, detail.trial_id, 'image');
}

export function fs_trial_back(): FsImage {
  return {
    layout: FsImageLayout.TRIAL_CARD,
    value: '/public/images/worldhaven/trial-cards/frosthaven/fh-trial-cards-back.webp',
  };
}

// Layout specifies the size and transformation when displaying the image.
// Monster stat card images are square, but we display only the relevant
// portion.
export enum FsImageLayout {
  NONE = 0,
  PORTRAIT_SQUARE = 1,
  PORTRAIT_TALL = 2,
  CLASS_ABILITY = 3,
  CLASS_MAT = 4,
  CLASS_MAT_TALL = 5,
  CLASS_SHEET = 6,
  MONSTER_ABILITY = 7,
  MONSTER_STAT_0 = 8,
  MONSTER_STAT_1 = 9,
  MONSTER_STAT_2 = 10,
  MONSTER_STAT_3 = 11,
  ITEM = 12,
  SUMMON_ABILITY_TOP = 13,
  SUMMON_ABILITY_BOTTOM = 14,
  SUMMON_ITEM = 15,
  ATTACK_MODIFIER = 16,
  MONSTER_STAT_TOP = 17,
  MONSTER_STAT_BOTTOM = 18,
  EVENT = 19,
  QUEST = 20,
  DUNGEON = 21,
  BATTLE_GOAL = 22,
  RANDOM_SIDE_SCENARIO = 23,
  SCENARIO_PAGE = 24,
  LOOT_CARD = 25,
  BUILDING_CARD = 26,
  PERK_REMINDER = 27,
  CHALLENGE_CARD = 28,
  TRIAL_CARD = 29,
  PET_CARD = 30,
  SUMMON_SVG = 31,
  PORTRAIT_SVG = 32,
  MONSTER_STAT = 33,
  SUMMON = 34,
  MONSTER_ABILITY_FULL = 35,
}

export interface FsLayoutInfo {
  layout: FsImageLayout;
  name: string;
  view_box: string;
  width: number;
  height: number;
  transform: string;
  is_svg?: boolean;
}

export const layout_info: FsLayoutInfo[] = [
  {
    layout: FsImageLayout.NONE,
    name: 'none',
    view_box: '0 0 200 200',
    width: 200,
    height: 200,
    transform: '',
  },
  {
    layout: FsImageLayout.PORTRAIT_SQUARE,
    name: 'portrait_square',
    view_box: '0 0 200 200',
    width: 200,
    height: 200,
    transform: '',
  },
  {
    layout: FsImageLayout.PORTRAIT_TALL,
    name: 'portrait_tall',
    view_box: '0 0 214 300',
    width: 214,
    height: 300,
    transform: '',
  },
  {
    layout: FsImageLayout.CLASS_ABILITY,
    name: 'class_ability',
    view_box: '0 0 400 600',
    width: 400,
    height: 600,
    transform: '',
  },
  {
    layout: FsImageLayout.CLASS_MAT,
    name: 'class_mat',
    view_box: '0 0 600 400',
    width: 600,
    height: 400,
    transform: '',
  },
  {
    layout: FsImageLayout.CLASS_MAT_TALL,
    name: 'class_mat_tall',
    view_box: '0 0 400 600',
    width: 400,
    height: 600,
    transform: '',
  },
  {
    layout: FsImageLayout.CLASS_SHEET,
    name: 'class_sheet',
    view_box: '0 0 500 611',
    width: 500,
    height: 611,
    transform: '',
  },
  {
    layout: FsImageLayout.MONSTER_ABILITY,
    name: 'monster_ability',
    view_box: '0 0 600 400',
    width: 600,
    height: 400,
    transform: '',
  },
  {
    layout: FsImageLayout.MONSTER_STAT_0,
    name: 'monster_stat_0',
    view_box: '0 0 200 100',
    width: 200,
    height: 100,
    transform: 'translate(100 140) scale(2.8) translate(-100 -50)',
  },
  {
    layout: FsImageLayout.MONSTER_STAT_1,
    name: 'monster_stat_1',
    view_box: '0 0 200 100',
    width: 200,
    height: 100,
    transform: 'translate(100 140) scale(2.8) rotate(270) translate(-100 -50)',
  },
  {
    layout: FsImageLayout.MONSTER_STAT_2,
    name: 'monster_stat_2',
    view_box: '0 0 200 100',
    width: 200,
    height: 100,
    transform: 'translate(100 140) scale(2.8) rotate(180) translate(-100 -50)',
  },
  {
    layout: FsImageLayout.MONSTER_STAT_3,
    name: 'monster_stat_3',
    view_box: '0 0 200 100',
    width: 200,
    height: 100,
    transform: 'translate(100 140) scale(2.8) rotate(90) translate(-100 -50)',
  },
  {
    layout: FsImageLayout.ITEM,
    name: 'item',
    view_box: '0 0 400 600',
    width: 400,
    height: 600,
    transform: '',
  },
  {
    layout: FsImageLayout.SUMMON_ABILITY_TOP,
    name: 'summon_ability_top',
    view_box: '0 0 200 120',
    width: 200,
    height: 120,
    transform: 'translate(100 145) scale(3) translate(-100 -60)',
  },
  {
    layout: FsImageLayout.SUMMON_ABILITY_BOTTOM,
    name: 'summon_ability_bottom',
    view_box: '0 0 200 120',
    width: 200,
    height: 120,
    transform: 'translate(100 -20) scale(3) translate(-100 -60)',
  },
  {
    layout: FsImageLayout.SUMMON_ITEM,
    name: 'summon_item',
    view_box: '0 0 200 100',
    width: 200,
    height: 100,
    transform: 'translate(100 -25) scale(3) translate(-100 -50)',
  },
  {
    layout: FsImageLayout.ATTACK_MODIFIER,
    name: 'attack_modifier',
    view_box: '0 0 600 400',
    width: 600,
    height: 400,
    transform: '',
  },
  {
    layout: FsImageLayout.MONSTER_STAT_TOP,
    name: 'monster_stat_top',
    view_box: '0 0 200 100',
    width: 200,
    height: 100,
    transform: 'translate(100 100) scale(2) translate(-100 -50)',
  },
  {
    layout: FsImageLayout.MONSTER_STAT_BOTTOM,
    name: 'monster_stat_bottom',
    view_box: '0 0 200 100',
    width: 200,
    height: 100,
    transform: 'translate(100 100) scale(2) rotate(180) translate(-100 -50)',
  },
  {
    layout: FsImageLayout.EVENT,
    name: 'event',
    view_box: '0 0 400 600',
    width: 400,
    height: 600,
    transform: '',
  },
  {
    layout: FsImageLayout.QUEST,
    name: 'quest',
    view_box: '0 0 400 600',
    width: 400,
    height: 600,
    transform: '',
  },
  {
    layout: FsImageLayout.DUNGEON,
    name: 'dungeon',
    view_box: '0 0 400 600',
    width: 400,
    height: 600,
    transform: '',
  },
  {
    layout: FsImageLayout.BATTLE_GOAL,
    name: 'battle_goal',
    view_box: '0 0 400 600',
    width: 400,
    height: 600,
    transform: '',
  },
  {
    layout: FsImageLayout.RANDOM_SIDE_SCENARIO,
    name: 'random_side_scenario',
    view_box: '0 0 400 600',
    width: 400,
    height: 600,
    transform: '',
  },
  {
    layout: FsImageLayout.SCENARIO_PAGE,
    name: 'scenario_page',
    view_box: '0 0 1313 1688',
    width: 1313,
    height: 1688,
    transform: '',
  },
  {
    layout: FsImageLayout.LOOT_CARD,
    name: 'loot_card',
    view_box: '0 0 400 600',
    width: 400,
    height: 600,
    transform: '',
  },
  {
    layout: FsImageLayout.BUILDING_CARD,
    name: 'building',
    view_box: '0 0 400 600',
    width: 400,
    height: 600,
    transform: '',
  },
  {
    layout: FsImageLayout.PERK_REMINDER,
    name: 'perk_reminder',
    view_box: '0 0 600 400',
    width: 600,
    height: 400,
    transform: '',
  },
  {
    layout: FsImageLayout.CHALLENGE_CARD,
    name: 'challenge_card',
    view_box: '0 0 400 600',
    width: 400,
    height: 600,
    transform: '',
  },
  {
    layout: FsImageLayout.TRIAL_CARD,
    name: 'trial_card',
    view_box: '0 0 400 600',
    width: 400,
    height: 600,
    transform: '',
  },
  {
    layout: FsImageLayout.PET_CARD,
    name: 'pet_card',
    view_box: '0 0 400 600',
    width: 400,
    height: 600,
    transform: '',
  },
  {
    layout: FsImageLayout.SUMMON_SVG,
    name: 'summon_svg',
    view_box: '0 0 1000 1000',
    width: 1000,
    height: 1000,
    transform: '',
    is_svg: true,
  },
  {
    layout: FsImageLayout.PORTRAIT_SVG,
    name: 'portrait_svg',
    view_box: '0 0 1000 1000',
    width: 1000,
    height: 1000,
    transform: '',
    is_svg: true,
  },
  {
    layout: FsImageLayout.MONSTER_STAT,
    name: 'monster_stat',
    view_box: '0 0 200 100',
    width: 200,
    height: 100,
    transform: '',
  },
  {
    layout: FsImageLayout.SUMMON,
    name: 'summon',
    view_box: '0 0 200 120',
    width: 200,
    height: 120,
    transform: '',
  },
  {
    layout: FsImageLayout.MONSTER_ABILITY_FULL,
    name: 'monster_ability_full',
    view_box: '0 0 400 600',
    width: 400,
    height: 600,
    transform: '',
  },
];
